import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ordersContext from '../../context/orders/ordersContext';

const NavBarItem = ({ label, icon, to, isOrder }) => {
  const { orderItems } = useContext(ordersContext);
  const location = useLocation();
  const isSelected = location.pathname === to;

  return (
    <li>
      <Link to={to} className={isSelected ? 'active' : ''}>
        {isOrder && orderItems.length > 0 ? (
          <div className="ordersBar-length">{orderItems.length}</div>
        ) : null}
        <div className="ordersBar">{icon}</div>
        {label}
      </Link>
    </li>
  );
};

export default NavBarItem;
