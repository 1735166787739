import { Check, ChevronDown, ChevronRight, ExternalLink } from 'lucide-react';
import { RiTestTubeLine } from 'react-icons/ri';
import React from 'react';
import './OrdersDetails.scss';
import UniInfo from './uniInfo/UniInfo';
import ReactGA from "react-ga4"

const OrderDeatils = ({ visibleOrder, handleVisibleChange, orders }) => {
  visibleOrder && ReactGA.event({
    category: "Tab Open",
    action: "Order Information",
    label: "Orders Page",
  });

  return (
    <div className="order-details">
      <div className="order-details__main">
        <div className="order-details__main link">
          <div onClick={handleVisibleChange} className="order-details__main link-pointer">
            {visibleOrder ? <ChevronDown color="#36C6E6" /> : <ChevronRight color="#36C6E6" />}
            <p className="order-details__main link-text">
              Order ID:<span>{orders?.id}</span>
            </p>
          </div>
          <ExternalLink color="#36C6E6" />
        </div>
        {orders?.status === 'Pending' && (
          <div className="order-details__main status">
          <Check color='#000' />
          </div>
        )}
     
        {orders?.status === 'Completed' && (
          <div className="order-details__main complet">
          <Check color='#000' />
          </div>
        )}
    
    
        {orders?.status === 'InProgress' && (
          <div className="order-details__main transit">
          <RiTestTubeLine size={24} color="#000" />
          In Transit
          </div>
        )} 
     
        {orders?.status === 'Cancelled' && (
          <div className="order-details__main cancel">
            <RiTestTubeLine size={24} color="#000" />
            Cancelled
          </div>
        )}  
      </div>
      <div className="order-details__divider" />
      {visibleOrder && (
        <div>
          <UniInfo orders={orders} />
        </div>
      )}
    </div>
  );
};

export default OrderDeatils;
