import React, { useContext } from 'react';
import Modal from 'react-modal';
import { modalsProfileContext } from '../../context/modalProfileContext/modalsProfileContext';
import { modalStyles } from '../../utils/helpModal';
import './ModalChangeUser.scss';
import ChangeEmail from '../../updateUserContent/ChangeEmail';
import ChangePass from '../../updateUserContent/ChangePass';
import RemoveAccount from '../../updateUserContent/RemoveAccount';

const ModalChangeUser = () => {
  const { changeUserInfo, setChangeUserInfo, changeEmail, changePass, removeUserAcc } =
    useContext(modalsProfileContext);

  function closeModal() {
    setChangeUserInfo(false);
  }

  return (
    <Modal
      isOpen={changeUserInfo}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div className="change-user">
        <div className="change-user__close" onClick={closeModal}>
          X
        </div>
        {changeEmail && <ChangeEmail />}
        {changePass && <ChangePass />}
        {removeUserAcc && <RemoveAccount />}
      </div>
    </Modal>
  );
};

export default ModalChangeUser;
