import React from 'react';
import './UpdatedUserCont.scss';
import ButtonWhite from '../ui/button/buttonWhite/ButtonWhite';
import ButtonGradient from '../ui/button/ButtonGradient';
import { Col, Row, Form, Input } from 'antd';

const ChangePass = () => {
  const onFinish = values => {
    // console.log('Success:', values);
  };
  const onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div className="change-box">
      <div>
        <h3 className="change-box__title">Change Password</h3>
        <p className="change-box__provide">Please create a new strong password.</p>
      </div>

      <Form
        name="basic"
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: 'Please input your current password!',
            },
          ]}
        >
          <Input.Password placeholder="Please enter here..." />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password placeholder="Please enter here..." />
        </Form.Item>
        <ul>
          <li>At least 8 characters—the longer, the better</li>
          <li>A mixture of both uppercase and lowercase letters</li>
          <li>A mixture of letters and numbers</li>
          <li>Inclusion of at least one special character.</li>
        </ul>
        <Form.Item
          label="Confirm Password"
          name="confPassword"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
          ]}
        >
          <Input.Password placeholder="Please enter here..." />
        </Form.Item>
        <Row justify="space-between">
          <Col span={9}>
            <ButtonWhite text="CANCEL" />
          </Col>
          <Col span={13}>
            <ButtonGradient text="CONTINUE" htmlType="submit" />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ChangePass;
