import { Col, Row, Input, Spin } from 'antd';
import React, { useContext } from 'react';
import ButtonWhite from '../../../../../components/ui/button/buttonWhite/ButtonWhite';
import ButtonGradient from '../../../../../components/ui/button/ButtonGradient';
import '../personal/PersonalInfo.scss';
import userAccountContext from '../../../../../components/context/userAccount/userAccountContext';
const WorkInfo = ({
  data,
  editWorkMode,
  handleCancelEdit,
  handleSaveClick,
  loading,
}) => {
  const {
    companyName,
    setCompanyName,
    userPosition,
    setUserPosition,
    companyEmail,
    setCompanyEmail,
  } = useContext(userAccountContext);

  console.log(data);

  return (
    <div className="personal">
      <div className="personal__workBox">
        {loading ? (
          <Spin size="default" />
        ) : (
          <>
            <Row justify="space-between">
              <Col span={11}>
                <div>
                  <p className="personal-title">Company Name</p>
                  {editWorkMode && (
                    <Input
                      type="text"
                      defaultValue={data?.firstName}
                      value={companyName}
                      onChange={({ target }) => setCompanyName(target.value)}
                    />
                  )}
                  {!editWorkMode && (
                    <p className="personal-text">
                      {data?.companyName ? data?.companyName : ''}
                    </p>
                  )}
                </div>
              </Col>
              <Col span={11}>
                <div>
                  <p className="personal-title">Your Position</p>
                  {editWorkMode && (
                    <Input
                      type="text"
                      defaultValue={data?.userPosition}
                      value={userPosition}
                      onChange={({ target }) => setUserPosition(target.value)}
                    />
                  )}
                  {!editWorkMode && (
                    <p className="personal-text">
                      {data?.userPosition ? data?.userPosition : ''}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col span={11}>
                <div>
                  <p className="personal-title">Company Email</p>
                  {editWorkMode && (
                    <Input
                      type="text"
                      defaultValue={data?.companyEmail}
                      value={companyEmail}
                      onChange={({ target }) => setCompanyEmail(target.value)}
                    />
                  )}
                  {!editWorkMode && (
                    <p className="personal-text">
                      {data?.companyEmail ? data?.companyEmail : ''}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
            {editWorkMode && (
              <Row justify="space-between">
                <Col span={10}>
                  <ButtonWhite
                    text="CANCEL"
                    className="user-cartBox__cancelButton"
                    handleClick={handleCancelEdit}
                  />
                </Col>

                <Col span={12}>
                  <ButtonGradient text="SAVE" handleClick={handleSaveClick} />
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WorkInfo;
