import React from 'react';
import './UpdatedUserCont.scss';
import ButtonWhite from '../ui/button/buttonWhite/ButtonWhite';
import ButtonGradient from '../ui/button/ButtonGradient';
import { Col, Row, Form, Input } from 'antd';

const ChangeEmail = () => {
  const onFinish = values => {
    // console.log('Success:', values);
  };
  const onFinishFailed = errorInfo => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <div className="change-box">
      <div>
        <h3 className="change-box__title">Change Email</h3>
        <p className="change-box__provide">Please provide all necessary information.</p>
      </div>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Current Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password placeholder="Please enter here..." />
        </Form.Item>
        <Form.Item
          label="New Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your email!',
              type: 'email',
            },
          ]}
        >
          <Input placeholder="Please enter here..." />
        </Form.Item>

        <Row justify="space-between">
          <Col span={9}>
            <ButtonWhite text="CANCEL" />
          </Col>
          <Col span={13}>
            <ButtonGradient text="CONTINUE" htmlType="submit" />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ChangeEmail;
