import React from 'react';
import './TeamDetails.scss';
import { Col, Row } from 'antd';

const TeamDetails = ({ member }) => {
  return (
    <Row className="team">
      <Col span={10}>
        <img src={member.image} alt={member.name} className="team__img" />
      </Col>

      <Col span={12} className="team__text">
        <p className="team__text name">{member.name}</p>

        <p className="team__text position">{member.position}</p>
      </Col>
    </Row>
  );
};

export default TeamDetails;
