import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import ButtonConfirm from '../../../../../components/ui/button/buttonConfirm/ButtonConfirm';
import { modalsProfileContext } from '../../../../../components/context/modalProfileContext/modalsProfileContext';
import '../personal/PersonalInfo.scss';

const RemoveProfile = () => {
  const { setChangeUserInfo, setChangeEmail, setChangePass, setRemoveUserAcc } =
    useContext(modalsProfileContext);
  const handleOpenRemoveModal = () => {
    setRemoveUserAcc(true);
    setChangeUserInfo(true);
    setChangeEmail(false);
    setChangePass(false);
  };

  return (
    <div className="personal">
      <Row className="personal-removeTitle">
        <p>
          <b>You can delete your account.</b>
        </p>
        <p>All data associated with your account would be permanently lost.</p>
      </Row>

      <Row justify="space-between" align="middle">
        <Col span={10}>
          <ButtonConfirm text="DELETE MY ACCOUNT" handleClick={handleOpenRemoveModal} />
        </Col>
        <Col span={12}></Col>
      </Row>
    </div>
  );
};

export default RemoveProfile;
