import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss';

import profileInfoContext from './components/context/profileInfoContext';

import Orders from './pages/roleUserPages/orders/Orders';

import Root from './Root';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import SubmitCheckout from './components/userRole/submitCheckout/SubmitCheckout';
import UserAcount from './pages/roleUserPages/account/UserAcount';
import Messenger from './pages/roleUserPages/messenger/Messenger';
import Team from './pages/roleUserPages/team/Team';
import { BACKEND_URL } from './services/serverData';
import QuotePage from './pages/roleUserPages/Quotes/QuotesPage';

import ReactGA from 'react-ga4';

const developmentGATag = 'G-QLNEJTDJZN';
const productionGATag = 'G-X4C5LV7E8F';
const demoGATag = 'G-R5WN3K1ZCQ';


const GATag =
  process.env.REACT_APP_ENV === "DEV"
    ? developmentGATag
    : process.env.REACT_APP_ENV === "PROD"
    ? productionGATag
    : process.env.REACT_APP_ENV === "DEMO"
    ? demoGATag
    : developmentGATag;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/orders',
        element: <Orders />,
      },
      {
        path: '/submission/:orderId/:submitId',
        element: <SubmitCheckout />,
      },
      {
        path: '/account',
        element: <UserAcount />,
      },
      {
        path: '/messenger',
        element: <Messenger />,
      },
      {
        path: '/team',
        element: <Team />,
      },
      {
        path: '/quotes',
        element: <QuotePage />,
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

function App() {
  ReactGA.initialize(GATag);

  const { setUserProfile, setToken } = useContext(profileInfoContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('gravl_token');

    if (token) {
      setIsLoading(true);
      fetch(`${BACKEND_URL}/auth`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === 'success') {
            setUserProfile(data.data);
            setToken(token);
          } else {
            localStorage.removeItem('gravl_token');
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [setToken, setUserProfile]);

  return (
    <div className="App">
      <>
        {isLoading ? (
          <div className="loading">
            <Spin size="large">
              <div className="content" />
            </Spin>
            <p className="loading__text">Loading</p>
          </div>
        ) : (
          <Router />
        )}
      </>
    </div>
  );
}

export default App;
