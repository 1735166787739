import { createContext, useState } from 'react';

export const modalsProfileContext = createContext();

export function ModalsUserProvider({ children }) {
  const [changeUserInfo, setChangeUserInfo] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);
  const [changePass, setChangePass] = useState(false);
  const [removeUserAcc, setRemoveUserAcc] = useState(false);

  return (
    <modalsProfileContext.Provider
      value={{
        changeUserInfo,
        setChangeUserInfo,
        changeEmail,
        setChangeEmail,
        changePass,
        setChangePass,
        removeUserAcc,
        setRemoveUserAcc,
      }}
    >
      {children}
    </modalsProfileContext.Provider>
  );
}
