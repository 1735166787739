import React from 'react';
import '../UserAccount.scss';

const UserCart = ({
  title,
  editIcon,
  content,
  handleShippVisible,
  handleEditPersonalInfo,
  collapse,
}) => {
  return (
    <div className="user-cartBox">
      <div className="user-cartBox__edit">
        <h2 className="user-cartBox__title">{title}</h2>
        {editIcon && (
          <div className="user-cartBox__icon" onClick={handleEditPersonalInfo}>
            {editIcon}
          </div>
        )}
        {collapse && (
          <div className="user-cartBox__collapse" onClick={handleShippVisible}>
            {collapse}
          </div>
        )}
      </div>
      {content}
    </div>
  );
};

export default UserCart;
