import React from 'react';
import './StepTable.scss';
import TableRowDeliverables from './rowItem/TableRowDeliverables';

const StepTable = ({
  deliverableData,
  setSelectedOption,
  setSelectedOption1,
  setSelectedFastMulti,
  setSelecteRowFile,
  selectedOption,
  selectedOption1,
  selectedOptionBam,
  selectedFastMulti,
  selecteRowFile,
  setSelectedOptionBam,
}) => {
  const handleSwitchChange = isChecked => {
    setSelectedOption(isChecked);
  };
  const handleSwitchChange1 = isChecked => {
    setSelectedOption1(isChecked);
  };
  const handleSwitchBam = isChecked => {
    setSelectedOptionBam(isChecked);
  };
  const handleSwitchFastMulti = isChecked => {
    setSelectedFastMulti(isChecked);
  };
  const handleSwitchRowFile = isChecked => {
    setSelecteRowFile(isChecked);
  };

  // console.log(deliverableData);
  return (
    <div className="stepTable">
      <table>
        <tbody>
          <tr>
            <th>Beneficiary Name</th>
            <th>Price</th>
          </tr>

          {deliverableData?.bamAlignment.Enabled === true ? (
            <TableRowDeliverables
              include={deliverableData?.bamAlignment.Price}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              handleSwitchChange={handleSwitchChange}
              rowTitle="BAM Alignment"
            />
          ) : null}
          {deliverableData?.bamDemultiplexed.Enabled === true ? (
            <TableRowDeliverables
              include={deliverableData?.bamDemultiplexed.Price}
              setSelectedOption={setSelectedOption1}
              selectedOption={selectedOption1}
              handleSwitchChange={handleSwitchChange1}
              rowTitle="BAM Demultiplexed"
            />
          ) : null}
          {deliverableData?.bamMultiplexed.Enabled === true ? (
            <TableRowDeliverables
              include={deliverableData?.bamMultiplexed.Price}
              setSelectedOption={setSelectedOptionBam}
              selectedOption={selectedOptionBam}
              handleSwitchChange={handleSwitchBam}
              rowTitle="BAM Multiplexed"
            />
          ) : null}
          {deliverableData?.fastqDemultiplexed.Enabled === true ? (
            <TableRowDeliverables
              include={deliverableData?.fastqDemultiplexed.Price}
              setSelectedOption={setSelectedFastMulti}
              selectedOption={selectedFastMulti}
              handleSwitchChange={handleSwitchFastMulti}
              rowTitle="FASTQ Multiplexed"
            />
          ) : null}
          {deliverableData?.rawFiles.Enabled === true ? (
            <TableRowDeliverables
              include={deliverableData?.rawFiles.Price}
              setSelectedOption={setSelecteRowFile}
              selectedOption={selecteRowFile}
              handleSwitchChange={handleSwitchRowFile}
              rowTitle="Raw files"
            />
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export default StepTable;
