import React, { useEffect, useState } from 'react';

import Header from './components/header/Header';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Login from './pages/login/Login';
import axios from 'axios';
import { BACKEND_URL } from './services/serverData';
import { Spin } from 'antd';

const Root = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [tokenIsValid, setTokenIsValid] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('gravl_token');
    let cookieToken = '';

    if (!token) {
      const tokenCookie = document.cookie
        .split(';')
        .find(row => row.trim().startsWith('gravl_token='));
      if (tokenCookie) {
        cookieToken = tokenCookie.split('=')[1];
      }
    }

    setIsLoading(true);

    const checkToken = async () => {
      // console.log('token', token);
      // console.log('cookieToken', cookieToken);

      const tokenToValidate = token ? token : cookieToken;

      // console.log('tokenToValidate', tokenToValidate);

      try {
        const response = await axios.get(`${BACKEND_URL}/auth/`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenToValidate}`,
          },
        });

        if (response.status === 200) {
          //if token is valid we clear token from cookies
          document.cookie = 'gravl_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

          localStorage.setItem('gravl_token', tokenToValidate);
          setTokenIsValid(true);
        } else {
          setTokenIsValid(false);
        }
      } catch (error) {
        console.log('Error:', error);
      }
    };

    const getAccountData = async () => {
      try {
        const tokenFromLocalStorage = localStorage.getItem('gravl_token');

        const response = await axios.get(`${BACKEND_URL}/admin/account`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokenFromLocalStorage}`,
          },
        });

        const data = response.data.data;

        localStorage.removeItem('user_name');
        localStorage.removeItem('last_name');

        localStorage.setItem('user_name', data.personalInformation.firstName);
        localStorage.setItem('last_name', data.personalInformation.lastName);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.log('Error:', error);
      }
    };

    if (token || cookieToken) {
      (async () => {
        await checkToken();

        await getAccountData();
      })();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/' && tokenIsValid) {
      navigate('/orders');
    }
  }, [location.pathname, navigate, tokenIsValid]);

  return (
    <>
      {isLoading ? (
        <div className="loading">
          <Spin size="large">
            <div className="content" />
          </Spin>
          <p className="loading__text">Loading</p>
        </div>
      ) : tokenIsValid ? (
        <div className="main__container">
          <Header
            isLoading={isLoading}
            tokenIsValid={tokenIsValid}
            onLogout={() => {
              setTokenIsValid(false);
            }}
          />
          <Outlet />
        </div>
      ) : (
        <Login
          onSuccesfullLogin={() => {
            setTokenIsValid(true);
          }}
        />
      )}
    </>
  );
};

export default Root;
