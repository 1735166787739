import React from 'react';
import './SubmitCheckout.scss';
import { ArrowLeft } from 'lucide-react';
import CheckOut from '../../../pages/roleUserPages/CheckOut/CheckOut';
import { useNavigate, useParams } from 'react-router-dom';
import ReactGA from "react-ga4"

const SubmitCheckout = () => {
  const { submitId: id, orderId } = useParams();
  ReactGA.send({
    hitType: "pageview",
    page: `/submission/${orderId}/${id}`,
    title: "Order Summary Page"
  })

  const navigate = useNavigate();

  const handleCloseCheckout = () => {
    navigate('/orders');
  };

  return (
    <div className="submit-checkout">
      <div className="submit-checkout__back" onClick={handleCloseCheckout}>
        <ArrowLeft className="submit-checkout__back-icon" />
        Back to all orders
      </div>
      <div className="submit-checkout__order">
        Order ID: <span>{id}</span>
      </div>
      <CheckOut />
    </div>
  );
};

export default SubmitCheckout;
