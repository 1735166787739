// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quotes {
  padding: 36px;
  width: 100%;
  height: 100%;
}
.quotes__title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0;
}
.quotes__cards-title-container {
  padding: 0px;
}
.quotes__cards-title {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0;
}
.quotes__cards-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/roleUserPages/Quotes/QuotesPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EAGA,WAAA;EACA,YAAA;AADJ;AAGI;EACI,iBAAA;EACA,gBAAA;EACA,aAAA;AADR;AAII;EACI,YAAA;AAFR;AAKI;EACI,eAAA;EACA,gBAAA;EACA,aAAA;AAHR;AAMI;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,2BAAA;EACA,SAAA;AAJR","sourcesContent":[".quotes {\n    padding: 36px;\n\n\n    width: 100%;\n    height: 100%;\n\n    &__title {\n        font-size: 1.5rem;\n        font-weight: 500;\n        margin-top: 0;\n    }\n\n    &__cards-title-container {\n        padding: 0px;\n    }\n\n    &__cards-title {\n        font-size: 1rem;\n        font-weight: 500;\n        margin-top: 0;\n    }\n\n    &__cards-container {\n        display: flex;\n        flex-direction: row;\n        flex-wrap: wrap;\n        justify-content: flex-start;\n        gap: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
