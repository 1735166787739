import React from 'react';
import './ServiceProvider.scss';
import SubmissionItem from './item/SubmissionItem';

const ServiceProvider = ({ data }) => {
  return (
    <div className="service-provider">
      <SubmissionItem title="University" text={data[0]?.university?.name} />
      <SubmissionItem title="Facility" text={data[0]?.lab?.name} />
    </div>
  );
};

export default ServiceProvider;
