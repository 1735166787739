import React from 'react';
import ShippAddressCard from './card/ShippAddressCard';
import '../personal/PersonalInfo.scss';

const ShippingUser = ({
  shippVisible,
  data,
  editShippingMode,
  handleSaveClick,
  handleCancelEdit,
  handleEditPersonalInfo,
}) => {


  return (
    <>
      {shippVisible && (
        <div className="personal">
          {data?.map((item) => (
            <ShippAddressCard
              item={item}
              key={item.id}
              handleEditPersonalInfo={handleEditPersonalInfo}
              editShippingMode={editShippingMode}
              handleSaveClick={handleSaveClick}
              handleCancelEdit={handleCancelEdit}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ShippingUser;
