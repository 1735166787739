import { createContext, useState } from 'react';

export const userAccountContext = createContext();

export function UserAccountProvider({ children }) {
  //---Personal
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  //---Work
  const [companyName, setCompanyName] = useState('');
  const [userPosition, setUserPosition] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');

  //--Payment
  const [cardName, setCardName] = useState('');
  const [cardNumber, setCardNamber] = useState('');

  //--Shipping
  const [addressLine, setAdressLine] = useState('');
  const [addressAdditional, setAddressAdditional] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [zip, setZip] = useState('');

  const editedPersonalInfo = {
    personalInformation: {
      firstName: firstName,
      lastName: lastName,
      email: '',
      phoneNumber: phoneNumber,
      address: '',
    },
    workInformation: {
      companyName: companyName,
      userPosition: userPosition,
      companyEmail: companyEmail,
    },
    shippingInformation: {
      addressLine: addressLine,
      addressAdditional: addressAdditional,
      city: city,
      state: state,
      country: country,
      zip: zip,
    },
    paymentInformation: {
      cardNumber: cardNumber,
      cardCvv: '',
      cardName: cardName,
    },
  };

  return (
    <userAccountContext.Provider
      value={{
        firstName,
        setFirstName,
        editedPersonalInfo,
        lastName,
        setLastName,
        phoneNumber,
        setPhoneNumber,
        companyName,
        setCompanyName,
        userPosition,
        setUserPosition,
        companyEmail,
        setCompanyEmail,
        addressLine,
        setAdressLine,
        addressAdditional,
        setAddressAdditional,
        city,
        setCity,
        state,
        setState,
        country,
        setCountry,
        zip,
        setZip,
        cardNumber,
        setCardNamber,
        cardName,
        setCardName,
      }}
    >
      {children}
    </userAccountContext.Provider>
  );
}

export default userAccountContext;
