// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-summary-table {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 10px;
  padding: 10px;
}
.order-summary-table__header, .order-summary-table__row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}
.order-summary-table__header {
  font-weight: bold;
}
.order-summary-table__text, .order-summary-table__row-text {
  padding: 5px;
}
.order-summary-table__total {
  font-size: larger;
  font-weight: bold;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/roleUserPages/CheckOut/steps/OrderSummary/OrderSummaryTable/OrderSummaryTable.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,4BAAA;EACA,SAAA;EACA,aAAA;AACJ;AACI;EACE,aAAA;EACA,kCAAA;EACA,SAAA;AACN;AAEI;EACE,iBAAA;AAAN;AAGI;EACE,YAAA;AADN;AAII;EACI,iBAAA;EACA,iBAAA;EACA,gBAAA;AAFR","sourcesContent":[".order-summary-table {\n    display: grid;\n    grid-template-rows: auto 1fr;\n    gap: 10px;\n    padding: 10px;\n  \n    &__header, &__row {\n      display: grid;\n      grid-template-columns: 1fr 1fr 1fr;\n      gap: 10px;\n    }\n  \n    &__header {\n      font-weight: bold;\n    }\n  \n    &__text, &__row-text {\n      padding: 5px;\n    }\n\n    &__total {\n        font-size: larger;\n        font-weight: bold;\n        margin-top: 10px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
