import React from 'react';

const EnvironmetSettings = ({ row, setEnvirTable, index, envirTable }) => {
  const options = ['Blood', 'Soil', 'Water', 'Petri Dish', 'Other'];
  const optionsKit = ['ml', 'ul', 'Other'];

  return (
    <>
      {/* <input
        id="librarySize"
        placeholder="Sample..."
        type="number"
        value={row.sampleNumber}
        onChange={(e) => {
          const updatedRows = [...envirTable];
          updatedRows[index].sampleNumber = e.target.value;
          setEnvirTable(updatedRows);
        }}
      /> */}
      <input
        id="librarySize"
        placeholder="Sample..."
        type="text"
        value={row.sampleName}
        onChange={e => {
          const updatedRows = [...envirTable];
          updatedRows[index].sampleName = e.target.value;
          setEnvirTable(updatedRows);
        }}
      />
      <input
        id="librarySize"
        placeholder="Sample..."
        type="text"
        value={row.tubeBar}
        onChange={e => {
          const updatedRows = [...envirTable];
          updatedRows[index].tubeBar = e.target.value;
          setEnvirTable(updatedRows);
        }}
      />

      <select
        id="selectSize"
        value={row.sampleType}
        onChange={e => {
          const updatedRows = [...envirTable];
          updatedRows[index].sampleType = e.target.value;
          setEnvirTable(updatedRows);
        }}
      >
        <option value="" disabled>
          Select type...
        </option>

        {options.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>

      <input
        id="librarySize"
        placeholder="Volume..."
        type="number"
        value={row.volume}
        onChange={e => {
          const updatedRows = [...envirTable];
          updatedRows[index].volume = e.target.value;
          setEnvirTable(updatedRows);
        }}
      />

      <select
        id="selectSize"
        value={row.volumeUl}
        onChange={e => {
          const updatedRows = [...envirTable];
          updatedRows[index].volumeUl = e.target.value;
          setEnvirTable(updatedRows);
        }}
      >
        <option value="" disabled>
          Volume Unit...
        </option>

        {optionsKit.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        id="librarySize"
        placeholder="Organism..."
        type="number"
        value={row.organism}
        onChange={e => {
          const updatedRows = [...envirTable];
          updatedRows[index].organism = e.target.value;
          setEnvirTable(updatedRows);
        }}
      />
    </>
  );
};

export default EnvironmetSettings;
