import React, { useContext, useState } from 'react';
import { Col, Row, Input } from 'antd';
import { Pencil, ChevronDown, ChevronRight } from 'lucide-react';
import './ShippAddressCard.scss';
import ButtonWhite from '../../../../../../components/ui/button/buttonWhite/ButtonWhite';
import ButtonGradient from '../../../../../../components/ui/button/ButtonGradient';
import userAccountContext from '../../../../../../components/context/userAccount/userAccountContext';

const ShippAddressCard = ({
  item,
  key,
  editShippingMode,
  handleSaveClick,
  handleCancelEdit,
  handleEditPersonalInfo,
}) => {
  const {
    addressLine,
    setAdressLine,
    addressAdditional,
    setAddressAdditional,
    state,
    setState,
    country,
    city,
    zip,
    setZip,
    setCountry,
    setCity,
  } = useContext(userAccountContext);
  const [visibleCard, setVisibleCard] = useState(true);
  const handleVisibleCard = () => {
    setVisibleCard(!visibleCard);
  };

  console.log(item);

  return (
    <div className="address-card" key={key}>
      <Row justify="space-between" align="middle">
        <Col onClick={handleVisibleCard}>
          <div className="address-card__header">
            {visibleCard ? (
              <ChevronDown
                className="collapse-icon"
                size={18}
                color="#00B0F0"
              />
            ) : (
              <ChevronRight
                className="collapse-icon"
                size={18}
                color="#00B0F0"
              />
            )}
            <p>{item.addressLine === 'string' ? '-' : item.addressLine}</p>
          </div>
        </Col>
        <Col onClick={handleEditPersonalInfo}>
          <Pencil className="pencil-icon" size={18} color="#00B0F0" />
        </Col>
      </Row>
      {visibleCard && (
        <div className="address-card__box">
          <Row justify="space-between">
            <Col span={11}>
              <div>
                <p className="personal-title">Address Line</p>
                {editShippingMode && (
                  <Input
                    type="text"
                    value={addressLine}
                    onChange={({ target }) => setAdressLine(target.value)}
                  />
                )}
                {!editShippingMode && (
                  <p className="personal-text">
                    {item.addressLine === 'string' ? '-' : item.addressLine}
                  </p>
                )}
              </div>
            </Col>
            <Col span={11}>
              <div>
                <p className="personal-title">City</p>
                {editShippingMode && (
                  <Input
                    type="text"
                    value={city}
                    onChange={({ target }) => setCity(target.value)}
                  />
                )}
                {!editShippingMode && (
                  <p className="personal-text">
                    {item.city === 'string' ? '-' : item.city}
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              <div>
                <p className="personal-title">Building, floor, etc.</p>
                {editShippingMode && (
                  <Input
                    type="text"
                    value={addressAdditional}
                    onChange={({ target }) =>
                      setAddressAdditional(target.value)
                    }
                  />
                )}
                {!editShippingMode && (
                  <p className="personal-text">
                    {item.addressAdditional === 'string'
                      ? '-'
                      : item.addressAdditional}
                  </p>
                )}
              </div>
            </Col>
            <Col span={11}>
              <div>
                <p className="personal-title">State/Province</p>
                {editShippingMode && (
                  <Input
                    type="text"
                    value={state}
                    onChange={({ target }) => setState(target.value)}
                  />
                )}
                {!editShippingMode && (
                  <p className="personal-text">
                    {item.state === 'string' ? '-' : item.state}
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={11}>
              <div>
                <p className="personal-title">Country</p>
                {editShippingMode && (
                  <Input
                    type="text"
                    value={country}
                    onChange={({ target }) => setCountry(target.value)}
                  />
                )}
                {!editShippingMode && (
                  <p className="personal-text">
                    {item.country === 'string' ? '-' : item.country}
                  </p>
                )}
              </div>
            </Col>
            <Col span={11}>
              <div>
                <p className="personal-title">ZIP/Postal Code</p>
                {editShippingMode && (
                  <Input
                    type="number"
                    value={zip}
                    onChange={({ target }) => setZip(target.value)}
                  />
                )}
                {!editShippingMode && (
                  <p className="personal-text">
                    {item.zip === 'string' ? '-' : item.zip}
                  </p>
                )}
              </div>
            </Col>
          </Row>
          {editShippingMode && (
            <Row justify="space-between">
              <Col span={10}>
                <ButtonWhite
                  text="CANCEL"
                  className="user-cartBox__cancelButton"
                  handleClick={handleCancelEdit}
                />
              </Col>

              <Col span={12}>
                <ButtonGradient text="SAVE" handleClick={handleSaveClick} />
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default ShippAddressCard;
