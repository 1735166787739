import React, { useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';
import { BACKEND_URL } from '../../../../../services/serverData';
import './FirstQaStep.scss';

import CheckoutButtons from '../../buttons/CheckoutButtons';
import submissionContext from '../../../../../components/context/submissionContext/submissionContext';
import { useParams } from 'react-router-dom';
import CheckOutTotals from './CheckOutTotals';
import * as XLSX from 'xlsx';
import DefaultCard from './DefaultCard/DefaultCard';
import CobiomicCard from './CobiomicCard/CobiomicCard';
import ReactGA from 'react-ga4';
import DefaultIputTypeCard from './DefaultInputTypeCard/DefaultInputTypeCard';

const FirstQaStep = ({ current, next, stepKey }) => {
  const {
    qaData,
    setQaData,
    inputValues,
    setInputValues,
    setIsLoading,
    inputDnaValue,
    setDnaValue,
  } = useContext(submissionContext);
  const { orderId: id, submitId: subId } = useParams();
  ReactGA.send({
    hitType: 'pageview',
    page: `/submission/${id}/${subId}`,
    title: 'Sample Submission Details Page',
  });
  const orderId = id;

  const token = localStorage.getItem('gravl_token');

  const [isTableExpanded, setIsTableExpanded] = useState(true);
  const [uploadFileError, setUploadFileError] = useState(null);
  const [primers, setRowsPrimers] = useState([]);
  const [dnaSample, setDnaSample] = useState([]);
  const [envirTable, setEnvirTable] = useState([]);

  //states for cobiomic
  const [templateFile, setTemplateFile] = useState(null);
  const [manifestFile, setManifestFile] = useState(null);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [loadCobiomic, setLoadCobiomic] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/admin/services/${orderId}/configuration/sample-submission`,
        );

        // console.log('QA Configuration Data:', response.data);

        setQaData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orderId, setIsLoading, setQaData]);

  //--Environmental table
  useEffect(() => {
    const initialRows = [
      {
        id: 1,
        sampleNumber: envirTable.length + 1,
        sampleName: '',
        tubeBar: '',
        sampleType: '',
        volume: '',
        volumeUl: '',
        organism: '',
      },
    ];

    setEnvirTable(initialRows);
  }, []);

  useEffect(() => {
    const initialRows = [
      {
        id: 1,
        sampleNumber: dnaSample.length + 1,
        sampleName: '',
        tubeBar: '',
        sampleType: '',
        volumeUl: '',
        concentration: '',
        concentrationUnit: '',
        quantitationMethod: '',
        ratio: '',
        buffer: '',
        organism: '',
      },
    ];

    setDnaSample(initialRows);
  }, []);

  useEffect(() => {
    const initialRows = [
      {
        id: 1,
        sampleNumber: primers.length + 1,
        sampleName: '',
        tubeBar: '',
        sampleType: '',
        libPrapKit: '',
        volumeUl: '',
        concentration: '',
        concentrationUnit: '',
        quantitationMethod: '',
        libBp: '',
        libraryQCMethod: '',
        buffer: '',
        organism: '',
        barCodeLength: '',
        barcodeType: '',
        samPool: '',
      },
    ];

    setRowsPrimers(initialRows);
  }, []);

  const requestBodyEnvir = {
    samplesArr: envirTable.map((sample) => ({
      sampleNumber: Number(sample.sampleNumber),
      sampleName: sample.sampleName,
      tubeBarcode: sample.tubeBar,
      sampleType: sample.sampleType,
      volume: Number(sample.volume),
      volumeUnit: sample.volumeUl,
      organism: sample.organism,
    })),
  };

  const requestBodyRna = {
    samplesArr: dnaSample.map((sample) => ({
      sampleNumber: Number(sample.sampleNumber),
      sampleName: sample.sampleName,
      tubeBarcode: sample.tubeBar,
      sampleType: sample.sampleType,
      volume: Number(sample.volumeUl),
      concentration: Number(sample.concentration),
      concentrationUnit: sample.concentrationUnit,
      quantitationMethod: sample.quantitationMethod,
      ratio: Number(sample.ratio),
      buffer: sample.buffer,
      organism: sample.organism,
      quantitationParameters: {
        qubit: inputDnaValue.qubit?.toString() || '',
        nanodrop: inputDnaValue.nanodrop?.toString() || '',
      },
    })),
  };

  const requestBodyLibrary = {
    samplesArr: primers.map((sample) => ({
      sampleNumber: Number(sample.sampleNumber),
      sampleName: sample.sampleName,
      tubeBarcode: sample.tubeBar,
      sampleType: sample.sampleType,
      libraryPreparationKit: sample.libPrapKit,
      volume: Number(sample.volumeUl),
      concentration: Number(sample.concentration),
      concentrationUnit: sample.concentrationUnit,
      quantitationMethod: sample.quantitationMethod,
      librarySize: Number(sample.libBp),
      libraryQCMethod: sample.libraryQCMethod,
      buffer: sample.buffer,
      organism: sample.organism,
      barcodeType: sample.barcodeType,
      barcodeLength: Number(sample.barCodeLength),
      numberOfSamples: Number(sample.samPool),
      quantitationParameters: {
        qubit: inputValues.qubit?.toString() || '',
        nanodrop: inputValues.nanodrop?.toString() || '',
        qprc: inputValues.qprc?.toString() || '',
        fluorescentAssayFragmentAnalyser:
          inputValues.fluorescentAssayFragmentAnalyser?.toString() || '',
        fluorescentAssayBioAnalyser:
          inputValues.fluorescentAssayBioAnalyser?.toString() || '',
        fluorescentAssayTapeStation:
          inputValues.fluorescentAssayTapeStation?.toString() || '',
        librarySizeFragmentAnalyser:
          inputValues.librarySizeFragmentAnalyser?.toString() || '',
        librarySizeBioAnalyser:
          inputValues.librarySizeBioAnalyser?.toString() || '',
        librarySizeTapeStation:
          inputValues.librarySizeTapeStation?.toString() || '',
      },
    })),
  };

  const determineRequestBody = () => {
    const hasDnaSamples = dnaSample.some(
      (sample) => sample.sampleName || sample.sampleType || sample.volumeUl,
    );
    const hasPrimers = primers.some(
      (sample) => sample.sampleName || sample.sampleType || sample.volumeUl,
    );
    const hasEnvirSamples = envirTable.some(
      (sample) => sample.sampleName || sample.sampleType || sample.volume,
    );

    if (hasDnaSamples) {
      return requestBodyRna;
    } else if (hasPrimers) {
      return requestBodyLibrary;
    } else if (hasEnvirSamples) {
      return requestBodyEnvir;
    }

    return null;
  };

  const handleSubmitFirstStep = async (e) => {
    e.preventDefault();

    const requestBody = determineRequestBody();
    try {
      const response = await axios.put(
        `${BACKEND_URL}/admin/orders/${orderId}/SampleSubmission`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            orderId: orderId,
          },
        },
      );
      message.success('Sample submission updated successfully!');
      next();
      // console.log('Response:', response);
    } catch (error) {
      console.error('Error:', error);
      message.error(
        error.response?.data?.message ||
          'Failed to update sample submission. Please try again.',
      );
    }
  };

  const handleSubmitFiles = async () => {
    const formData = new FormData();

    formData.append('templateFile', templateFile);
    manifestFile && formData.append('manifestFile', manifestFile);
    setLoadCobiomic(true);
    try {
      const response = await axios.put(
        `${BACKEND_URL}/admin/orders/${orderId}/SampleSubmission`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
            orderId: orderId,
          },
        },
      );

      message.success('Sample submission updated successfully!');
      next();
      setLoadCobiomic(false);
    } catch (error) {
      console.error('Error:', error);
      message.error(
        error.response?.data?.message ||
          'Failed to update sample submission. Please try again.',
      );
    }
  };

  const handleAddRow = () => {
    const newRow = {
      id: primers.length + 2,
      sampleNumber: primers.length + 1,
      sampleName: '',
      tubeBar: '',
      sampleType: '',
      libPrapKit: '',
      volumeUl: '',
      concentration: '',
      concentrationUnit: '',
      quantitationMethod: '',
      libBp: '',
      libraryQCMethod: '',
      buffer: '',
      organism: '',
      barCodeLength: '',
      barcodeType: '',
      samPool: '',
    };

    setRowsPrimers([...primers, newRow]);
  };

  const handleDeleteRow = (id) => {
    const updatedRows = primers.filter((row) => row.id !== id);

    setRowsPrimers(updatedRows);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleAddRowDnaSample = () => {
    const newRow = {
      id: dnaSample.length + 2,
      sampleNumber: dnaSample.length + 1,
      sampleName: '',
      tubeBar: '',
      sampleType: '',
      volumeUl: '',
      concentration: '',
      concentrationUnit: '',
      quantitationMethod: '',
      ratio: '',
      buffer: '',
      organism: '',
    };

    setDnaSample([...dnaSample, newRow]);
  };

  const handleDeleteRowDnaSample = (id) => {
    const updatedRows = dnaSample.filter((row) => row.id !== id);

    setDnaSample(updatedRows);
  };

  const handleChangeDnaSample = (e) => {
    const { name, value } = e.target;
    setDnaValue({
      ...inputDnaValue,
      [name]: value,
    });
  };

  const handleAddEnvirTable = () => {
    const newRow = {
      id: envirTable.length + 2,
      sampleNumber: envirTable.length + 1,
      sampleName: '',
      tubeBar: '',
      sampleType: '',
      volume: '',
      volumeUl: '',
      organism: '',
    };

    setEnvirTable([...envirTable, newRow]);
  };

  const handleDeleteEnvirTable = (id) => {
    const updatedRows = envirTable.filter((row) => row.id !== id);

    setEnvirTable(updatedRows);
  };

  const handleChangeEnvirTable = (e) => {
    const { name, value } = e.target;
    setEnvirTable({
      ...inputDnaValue,
      [name]: value,
    });
  };

  const generateExcelTemplate = () => {
    let headers;
    let selectors;

    const backendToDisplayMap = {
      sampleNumber: 'Sample Number',
      sampleName: 'Sample Name',
      tubeBar: 'Tube Barcode',
      sampleType: 'Sample Type',
      quantitationMethod: 'Quantitation Method',
      libraryQCMethod: 'Library QC Method',
      barcodeType: 'Paired-End or Single-End Barcode',
      concentrationUnit: 'Concentration Unit',
      volumeUl: 'Volume (uL)',
      concentration: 'Concentration',
      ratio: '260/280 Ratio',
      buffer: 'Buffer',
      organism: 'Organism',
      volume: 'Volume',
      libPrapKit: 'Library Prep. Kit',
      libBp: 'Library Size(bp)',
      barCodeLength: 'Barcode Length(bp)',
      samPool: 'Samples Pooled',
    };

    if (qaData?.sampleType === 'Library') {
      headers = primers;

      selectors = [
        {
          name: 'quantitationMethod',
          values: [
            'qPCR',
            'Nanodrop',
            'Qubit',
            'Bioanalyzer',
            'TapeStation',
            'Fragment Analyzer',
            'Other',
          ],
        },
        {
          name: 'libraryQCMethod',
          values: ['Bioanalyzer', 'TapeStation', 'Fragment Analyzer'],
        },
        {
          name: 'barcodeType',
          values: ['Paired-End', 'Single-End'],
        },
        {
          name: 'sampleType',
          values: ['DNA Sequencing Library', 'RNA Sequencing Library'],
        },
        {
          name: 'concentrationUnit',
          values: ['nM', 'ng/ul'],
        },
      ];
    } else if (qaData?.sampleType === 'DNAorRNA') {
      headers = dnaSample;

      selectors = [
        {
          name: 'sampleType',
          values: ['DNA', 'RNA'],
        },
        {
          name: 'concentrationUnit',
          values: ['nM', 'ng/ul'],
        },
        {
          name: 'quantitationMethod',
          values: ['Nanodrop', 'Qubit', 'Other'],
        },
      ];
    } else if (qaData?.sampleType === 'EnvironmentalSample') {
      headers = envirTable;

      selectors = [
        {
          name: 'sampleType', // Backend field name
          values: ['Blood', 'Soil', 'Water', 'Petri Dish', 'Other'],
        },
        {
          name: 'volumeUl', // Backend field name
          values: ['ml', 'ul', 'Other'],
        },
      ];
    } else {
      console.warn('No sample type found');

      return;
    }

    if (!headers || !headers[0]) {
      console.error('Headers are undefined or empty');

      return;
    }

    headers = Object.keys(headers[0]);

    if (headers[0] === 'id') {
      headers = headers.slice(1);
    }

    // Convert backend field names to user-friendly names for Excel
    const displayHeaders = headers.map(
      (header) => backendToDisplayMap[header] || header,
    );

    const data = [displayHeaders];

    // Create 5 rows with the corresponding data
    for (let i = 0; i < 5; i++) {
      const row = [];
      headers.forEach((header) => {
        const selector = selectors.find((selector) => selector.name === header);

        if (selector) {
          row.push({
            t: 's',
            v: selector.values[i % selector.values.length],
            r: selector.values[i % selector.values.length],
          });
        } else {
          row.push(''); // Empty field
        }
      });
      data.push(row);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');
    XLSX.writeFile(workbook, 'template.xlsx');
  };

  const backendFieldMap = {
    'Sample Number': 'sampleNumber',
    'Sample Name': 'sampleName',
    'Tube Barcode': 'tubeBar',
    'Sample Type': 'sampleType',
    'Quantitation Method': 'quantitationMethod',
    'Library QC Method': 'libraryQCMethod',
    'Paired-End or Single-End Barcode': 'barcodeType',
    'Concentration Unit': 'concentrationUnit',
    'Volume (uL)': 'volumeUl',
    Concentration: 'concentration',
    '260/280 Ratio': 'ratio',
    Buffer: 'buffer',
    Organism: 'organism',
    Volume: 'volume',
    'Library Prep. Kit': 'libPrapKit',
    'Library Size(bp)': 'libBp',
    'Barcode Length(bp)': 'barCodeLength',
    'Samples Pooled': 'samPool',
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const excelData = XLSX.utils.sheet_to_json(sheet, {
        header: 1, // Treat the first row as headers
        raw: false,
      });

      if (!excelData || !excelData.length) {
        console.error('No data found in the uploaded file');

        return;
      }

      const headers = excelData.shift(); // Get the headers

      if (!headers || headers.some((header) => header === undefined)) {
        console.error('Invalid or undefined headers');

        return;
      }

      // Convert headers from user-friendly names to backend-compatible names
      const backendHeaders = headers.map(
        (header) => backendFieldMap[header] || header,
      );

      let sampleType;
      let headersToCheck;

      if (qaData?.sampleType === 'Library') {
        headersToCheck = Object.keys(primers[0]);
        sampleType = 'Library';
      } else if (qaData?.sampleType === 'DNAorRNA') {
        headersToCheck = Object.keys(dnaSample[0]);
        sampleType = 'DNAorRNA';
      } else if (qaData?.sampleType === 'EnvironmentalSample') {
        headersToCheck = Object.keys(envirTable[0]);
        sampleType = 'EnvironmentalSample';
      } else {
        console.warn('No sample type found');

        return;
      }

      if (headersToCheck[0] === 'id') {
        headersToCheck = headersToCheck.slice(1);
      }

      // Check if headers match the expected backend headers
      const headersMatch = backendHeaders.every((header, index) => {
        return header === headersToCheck[index];
      });

      if (!headersMatch) {
        console.error('Headers do not match the expected format');

        return;
      }

      // Process valid rows based on sample type
      const validRows = excelData.filter((row) =>
        row.some((cell) => cell !== undefined),
      );

      if (sampleType === 'Library') {
        setRowsPrimers((prev) => {
          const newRows = validRows
            .map((row, idx) => {
              const parsedRow = row
                .map((cell, index) => [backendHeaders[index], cell])
                .filter((value) => Boolean(value));

              if (parsedRow.every((value) => value)) {
                return {
                  id: prev.length + 1 + idx,
                  ...Object?.fromEntries(parsedRow),
                };
              } else {
                return [];
              }
            })
            .flat();

          return [...newRows];
        });
      } else if (sampleType === 'DNAorRNA') {
        setDnaSample((prev) => {
          const newRows = validRows
            .map((row, idx) => {
              const parsedRow = row
                .map((cell, index) => [backendHeaders[index], cell])
                .filter((value) => Boolean(value));

              if (parsedRow.every((value) => value)) {
                return {
                  id: prev.length + 1 + idx,
                  ...Object?.fromEntries(parsedRow),
                };
              } else {
                return [];
              }
            })
            .flat();

          return [...newRows];
        });
      } else if (sampleType === 'EnvironmentalSample') {
        setEnvirTable((prev) => {
          const newRows = validRows
            .map((row, idx) => {
              const parsedRow = row
                .map((cell, index) => [backendHeaders[index], cell])
                .filter((value) => Boolean(value));

              if (parsedRow.every((value) => value)) {
                return {
                  id: prev.length + 1 + idx,
                  ...Object?.fromEntries(parsedRow),
                };
              } else {
                return [];
              }
            })
            .flat();

          return [...newRows];
        });
      }
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <div className="firstStep">
        <div
          className={`firstStep__inputs ${!isTableExpanded ? 'expanded' : ''}`}
        >
          {['Library', 'DNAorRNA', 'EnvironmentalSample'].some(
            (sampleType) => sampleType === qaData?.sampleType,
          ) ? (
            <DefaultCard
              qaData={qaData}
              isTableExpanded={isTableExpanded}
              setIsTableExpanded={setIsTableExpanded}
              primers={primers}
              setRowsPrimers={setRowsPrimers}
              handleAddRow={handleAddRow}
              handleDeleteRow={handleDeleteRow}
              handleSubmitFirstStep={handleSubmitFirstStep}
              handleChange={handleChange}
              dnaSample={dnaSample}
              setDnaSample={setDnaSample}
              handleAddRowDnaSample={handleAddRowDnaSample}
              handleDeleteRowDnaSample={handleDeleteRowDnaSample}
              handleChangeDnaSample={handleChangeDnaSample}
              envirTable={envirTable}
              setEnvirTable={setEnvirTable}
              handleAddEnvirTable={handleAddEnvirTable}
              handleDeleteEnvirTable={handleDeleteEnvirTable}
              handleChangeEnvirTable={handleChangeEnvirTable}
              generateExcelTemplate={generateExcelTemplate}
              handleFileUpload={handleFileUpload}
              uploadFileError={uploadFileError}
              setUploadFileError={setUploadFileError}
            />
          ) : null}

          {qaData?.sampleType === 'Cobiomic' && (
            <CobiomicCard
              templateFile={templateFile}
              setTemplateFile={setTemplateFile}
              manifestFile={manifestFile}
              setManifestFile={setManifestFile}
              filesUploaded={filesUploaded}
              setFilesUploaded={setFilesUploaded}
              isTableExpanded={isTableExpanded}
              setIsTableExpanded={setIsTableExpanded}
            />
          )}

          {qaData?.sampleType === 'Default' && (
            <DefaultIputTypeCard
              isTableExpanded={isTableExpanded}
              setIsTableExpanded={setIsTableExpanded}
              templateFile={templateFile}
              setTemplateFile={setTemplateFile}
              filesUploaded={filesUploaded}
              setFilesUploaded={setFilesUploaded}
              serviceType={qaData?.serviceType}
            />
          )}
        </div>
        {/* {isTableExpanded && <CheckOutTotals />} */}
      </div>

      {!loadCobiomic && (
        <CheckoutButtons
          current={current}
          next={next}
          handleSubmit={
            ['Cobiomic', 'Default'].some((type) => type === qaData?.sampleType)
              ? handleSubmitFiles
              : handleSubmitFirstStep
          }
          disabled={
            ['Cobiomic', 'Default'].some((type) => type === qaData?.sampleType)
              ? !filesUploaded
              : !primers || !dnaSample || !envirTable
          }
          buttonText={'CONTINUE'}
          stepKeySamplwSubmition={stepKey}
          dataSampleFirst={determineRequestBody() || filesUploaded}
        />
      )}
      {loadCobiomic && (
        <CheckoutButtons disabled={true} buttonText={'LOADING'} />
      )}
    </>
  );
};

export default FirstQaStep;
