import React from 'react';
import { Check, Minus } from 'lucide-react';
import SubmissionItem from './item/SubmissionItem';

const SequencPrimers = ({ primersData }) => {
  // console.log(primersData?.primers);
  // console.log(primersData?.indices);
  const isEmpty = primersData?.primers === null ? true : false;

  // console.log("isEmpty", isEmpty);

  const isEmptyIndicity = primersData?.indices === null ? true : false;

  return (
    <div className="service-provider">
      <SubmissionItem
        title="Samples are provided:"
        check={
          isEmpty ? (
            <Check className="check-icon" size={18} />
          ) : (
            <Minus className="check-icon" size={18} />
          )
        }
        primers={primersData?.primers}
      />
      <SubmissionItem
        title="Indices are provided"
        check={
          isEmptyIndicity ? (
            <Check className="check-icon" size={18} />
          ) : (
            <Minus className="check-icon" size={18} />
          )
        }
        indices={primersData?.indices}
      />
      {/* <SubmissionItem title="Indices index type" text="Dual Index" /> */}
    </div>
  );
};

export default SequencPrimers;
