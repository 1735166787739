import { createContext, useState } from 'react';

export const submissionContext = createContext();

export function SubmissionProvider({ children }) {
  const [qaData, setQaData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [inputValues, setInputValues] = useState({
    librarySizeTapeStation: '',
    librarySizeBioAnalyser: '',
    librarySizeFragmentAnalyser: '',
    fluorescentAssayTapeStation: '',
    fluorescentAssayBioAnalyser: '',
    fluorescentAssayFragmentAnalyser: '',
    qprc: '',
    qubit: '',
    nanodrop: '',
  });
  const [inputDnaValue, setDnaValue] = useState({
    nanodrop: '',
    qubit: '',
  });

  return (
    <submissionContext.Provider
      value={{
        qaData,
        setQaData,
        isLoading,
        setIsLoading,
        inputValues,
        setInputValues,
        inputDnaValue,
        setDnaValue,
      }}
    >
      {children}
    </submissionContext.Provider>
  );
}
export default submissionContext;
