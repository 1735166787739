import React from 'react';
import './Search.scss';
import { Input } from 'antd';
import { Search } from 'lucide-react';

const SearchInput = ({ onSearch }) => {
  const handleChange = e => {
    const value = e.target.value;
    onSearch(value); // Update the search state in the parent component
  };

  return (
    <div className="search">
      <Input
        placeholder="Start search by typing..."
        style={{ width: 200 }}
        prefix={<Search color="#36C6E6" size={24} />}
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchInput;
