import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useParams } from 'react-router-dom';
import { BACKEND_URL } from '../../../../../services/serverData';
import './CheckOutTotals.scss';

const CheckOutTotals = () => {
  const { orderId: id } = useParams();
  const [totalItem, setTotalItem] = useState();

  const token = localStorage.getItem('gravl_token');

  const orderId = id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/admin/orders/${orderId}/data`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        setTotalItem(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // console.log('finish');
      }
    };

    fetchData();
  }, [orderId, token]);

  return (
    <div className="total">
      <h2 className="total__title">Checkout Totals</h2>
      <div className="total__line">
        <b>Service:</b> {totalItem?.Service?.name}
      </div>
      <div className="total__line">
        <b>Host:</b> -
      </div>
      <div className="total__line">
        <b>Read Type:</b> -
      </div>
      <div className="total__line">
        <b>Reads:</b> -
      </div>
      <div className="total__line">
        <b>Flow Cell:</b> -
      </div>
      <div className="total__line">
        <b>Quantity:</b>
        &nbsp;{totalItem?.orderItem?.quantity}{' '}
      </div>
      <div className="total__line">
        <b>Samples Submitted:</b> -
      </div>
      <div className="total__line">
        <b>Shipping:</b> -
      </div>
      <div className="total__line">
        <b>Service Fee:</b> -
      </div>
      <div className="total__service">
        <b>Price/Flow Cell:</b> -
      </div>
      <div className="total__service">
        <b>Estimated Completion Date:</b> {totalItem?.Service?.details.readyIn}{' '}
        {totalItem?.Service?.details.readyIn === 1 ? 'day' : 'days'}
      </div>
      <div className="total__order">
        Order Total: <b> -</b>{' '}
      </div>
    </div>
  );
};

export default CheckOutTotals;
