import React, { useState } from 'react';
import { FileCheck, Info, Upload, X } from 'lucide-react';
import './ImportFileIndices.scss';

const ImportIndices = ({ isVisible, generateExcelTemplate, handleFileUpload }) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = event => {
    const files = event.target.files;

    handleFileUpload(event);
    if (files.length > 0) {
      const fileNames = Array.from(files)
        .map(file => truncateFileName(file.name))
        .join(', ');
      setFileName(fileNames);
    } else {
      setFileName('');
    }
  };

  const truncateFileName = name => {
    const maxLength = 20;
    if (name.length > maxLength) {
      const firstPart = name.substring(0, 2);
      const lastPart = name.substring(name.length - 6);

      return `${firstPart}...${lastPart}`;
    } else {
      return name;
    }
  };

  const handleRemoveFile = () => {
    setFileName('');
  };

  return (
    <div className="firstStep-fileBox">
      {!fileName ? (
        <>
          <div className="firstStep-fileBox__import">
            <form className="importIndices">
              <label htmlFor="file-upload" className="btn-white">
                <Upload className="quote-icon" size={20} /> IMPORT FLAT FILE
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                accept=".xlsx, .xls"
                className="file-upload"
              />
            </form>
          </div>
          {!isVisible && (
            <div className="firstStep-fileBox__import">
              <Info className="info-icon" size={24} />
              <p className="importIndices-box__text">
                In order to prepare the file properly, please use our
                <span onClick={generateExcelTemplate}>&nbsp;template blank file.</span>
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="importIndices-box">
            <div className="importIndices-box__icon">
              <FileCheck className="info-icon" size={20} color="#09C7BE" />
            </div>
            <div>
              <p className="importIndices-box__text">Imported Flat File</p>
              <p className="import-box__name">{fileName}</p>
            </div>
            <div onClick={handleRemoveFile} className="importIndices-box__remove">
              <X className="info-icon" size={20} color="#E83434" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImportIndices;
