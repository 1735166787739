import { Col, Row } from 'antd';
import React from 'react';

const SubmissionItem = ({ title, text, primers, indices, price }) => {
  return (
    <>
      <div className="service-provider__item">
        <div className="service-provider__item name">{title}</div>
        {price && <div>$ {price}</div>}
        {text && <div className="service-provider__item info">{text}</div>}
        {/* {check && <div className="service-provider__item info">{check} </div>} */}
      </div>
      <div className="service-provider__item primers">
        {primers?.map((item, index) => (
          <div item={item} key={index}>
            <Row justify="space-between">
              <Col>ID</Col>
              <Col>{item.id}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Instructions</Col>
              <Col>{item.instructions}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Molarity</Col>
              <Col>{item.molarity}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Primer Name</Col>
              <Col>{item.primerName}</Col>
            </Row>
            <Row justify="space-between">
              <Col>SampleName</Col>
              <Col>{item.sampleName}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Volume</Col>
              <Col>{item.volume}</Col>
            </Row>
          </div>
        ))}
        {indices?.map((item, index) => (
          <div item={item} key={index}>
            <Row justify="space-between">
              <Col>ID</Col>
              <Col>{item.id}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Name</Col>
              <Col>{item.name}</Col>
            </Row>
            <Row justify="space-between">
              <Col>Size</Col>
              <Col>{item.size}</Col>
            </Row>
          </div>
        ))}
      </div>
    </>
  );
};

export default SubmissionItem;
