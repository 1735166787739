import { createContext, useState } from 'react';

export const profileInfoContext = createContext();

export function ProfileProvider({ children }) {
  const [userProfile, setUserProfile] = useState();
  const [token, setToken] = useState('');

  return (
    <profileInfoContext.Provider value={{ userProfile, setUserProfile, token, setToken }}>
      {children}
    </profileInfoContext.Provider>
  );
}

export default profileInfoContext;
