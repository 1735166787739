import React from 'react';
import './UniInfo.scss';
import { Col, Row } from 'antd';

import { Mail, MapPin, Phone } from 'lucide-react';
import map from '../../../assets/images/map.png';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { formatPrice } from '../../../utils/currencyUtils';
const UniInfo = ({ orders }) => {
  const address = orders?.university?.details?.address;
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address,
  )}`;

  const paymentDatestamp = orders?.orderData?.paymentDatastamp;

  // If there's a payment datestamp, format it to "22 Apr 2024, 10:27 AM"
  let formattedPaymentDate = '-';
  if (paymentDatestamp) {
    const date = parseISO(paymentDatestamp);
    formattedPaymentDate = format(date, 'dd MMM yyyy, hh:mm a');
  }

  return (
    <Row className="uni-info">
      <Col span={7}>
        <h2 className="uni-info__title">{orders?.lab?.name}</h2>
        <p className="uni-info__uni">{orders?.university?.name}</p>
        <p className="uni-info__date">
          Start Date: <span>-</span>
        </p>
        <div className="uni-info__btns">
          {/* <ButtonWhite
            text="Message CORE"
            icon={<MessagesSquare className="quote-icon" size={20} />}
          />
          <ButtonWhite text="... MORE" /> */}
        </div>
      </Col>
      <Col span={9} className="uni-info__mapBox">
        <div className="uni-info__mapBox-section">
          <MapPin className="quote-icon" size={20} color="#36C6E6" />
          <p className="uni-info__mapBox-section text">{address}</p>
        </div>
        <div className="uni-info__mapBox-section">
          <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
            <img src={map} alt="map" className="uni-info__mapBox-section img" />
            <p className="uni-info__mapBox-section mail">View on Google Maps</p>
          </a>
        </div>
        <div className="uni-info__mapBox-section">
          <Mail className="quote-icon" size={20} color="#36C6E6" />
          <p className="uni-info__mapBox-section mail">
            {orders?.university?.details?.email}
          </p>
        </div>
        <div className="uni-info__mapBox-section">
          <Phone className="quote-icon" size={20} color="#36C6E6" />
          <p className="uni-info__mapBox-section text">
            {orders?.university?.details?.phone}
          </p>
        </div>
      </Col>
      <Col span={4} className="uni-info__total">
        <p>Subtotal</p>
        <p>Tax</p>
        <p>Order Total</p>
      </Col>
      <Col span={4} className="uni-info__total">
        <p>
          <b>{formatPrice(orders?.orderData?.total, orders?.university?.currency)}</b>
        </p>

        <p>
          <b>{orders?.orderData?.salesTax} %</b>
        </p>
        <p>
          <b>{formatPrice(orders?.orderData?.orderTotal, orders?.university?.currency)}</b>
        </p>
      </Col>
      <div className="order-details__divider" />
    </Row>
  );
};

export default UniInfo;
