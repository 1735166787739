import React, { useContext, useEffect, useState } from 'react';
import { Pencil } from 'lucide-react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BACKEND_URL } from '../../../../../services/serverData';
import './OrderSummary.scss';
import OrderCart from './orderCart/OrderCart';
import ServiceProvider from './summaryContent/ServiceProvider';
import SampleSubmission from './summaryContent/SampleSubmission';
import DataDeliverables from './summaryContent/DataDeliverables';
import SequencPrimers from './summaryContent/SequencPrimers';
import ShippingMethod from './summaryContent/ShippingMethod';
import PaymentMethod from './summaryContent/PaymentMethod';
import CheckoutButtons from '../../buttons/CheckoutButtons';

import ThankYou from './ThankYou/ThankYou';
import ordersContext from '../../../../../components/context/orders/ordersContext';
import SubmissionItem from './summaryContent/item/SubmissionItem';
import OrderSummaryTable from './OrderSummaryTable/OrderSummaryTable';
import ReactGA from "react-ga4"

const OrderSummary = ({
  current,
  prev,
  next,
  handleCloseCheckout,
  handleFirstStep,
  handleSecondStep,
  handleThirdStep,
  stepKey,
}) => {
  const { orderId: id, submitId: subId } = useParams();

  ReactGA.send({
    hitType: "pageview",
    page: `/submission/${id}/${subId}`,
    title: "Order Summary Page"
  })

  const orderId = id;
  const token = localStorage.getItem('gravl_token');
  const [submissionData, setSubmissionData] = useState([]);
  const [openThankYou, setOpenThankYou] = useState(false);
  const { orderItems } = useContext(ordersContext);

  const [filteredItems, setFilteredItems] = useState([]);

  // Filter `orderItems` based on matching `subId`
  useEffect(() => {
    if (orderItems && subId) {
      const matchingItems = orderItems.filter((item) => item.id === subId);
      setFilteredItems(matchingItems);
    }
  }, [orderItems, subId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/admin/orders/${orderId}`,

          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );
        const data = response.data;
        // console.log(data);
        setSubmissionData(data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
  }, [next, orderId, token]);

  const handleOpenThankYouModal = () => {
    setOpenThankYou(true);
  };

  const samplesArray = submissionData?.orderQAConfiguration?.samplesArray || [];

  return (
    <>
      {!openThankYou ? (
        <>
          <div className="order">
            <OrderCart
              title="Facility Information"
              content={<ServiceProvider data={filteredItems} />}
            />
            {samplesArray.length > 0 && (
              <OrderCart
                title="Sample Submission Details"
                editIcon={
                  <div onClick={handleFirstStep}>
                    <Pencil className="pencil-icon" size={18} />
                  </div>
                }
                content={samplesArray.map((item, index) => (
                  <SampleSubmission item={item} key={index} id={index} />
                ))}
              />
            )}
            {submissionData.orderDeliverablesConfiguration && (
              <OrderCart
                title="Data Deliverables"
                editIcon={
                  <div onClick={handleSecondStep}>
                    <Pencil className="pencil-icon" size={18} />
                  </div>
                }
                content={
                  <DataDeliverables
                    deliverData={submissionData.orderDeliverablesConfiguration}
                  />
                }
              />
            )}
            {submissionData.orderPrimersAndIndicesConfiguration && (
              <OrderCart
                title="Sequencing Primers and Indices"
                editIcon={
                  <div onClick={handleThirdStep}>
                    <Pencil className="pencil-icon" size={18} />
                  </div>
                }
                content={
                  <SequencPrimers
                    primersData={
                      submissionData.orderPrimersAndIndicesConfiguration
                    }
                  />
                }
              />
            )}
            {submissionData.orderSummary && (
              <OrderCart
                title="Order Summary"
                content={
                  <OrderSummaryTable data={submissionData.orderSummary} currency={submissionData.order.currency} />
                }
              />
            )}

            {/* {submissionData.orderOptionsConfiguration && (
              <OrderCart
                title="Shipping Address & Method"
                editIcon={
                  <div onClick={() => prev()}>
                    <Pencil className="pencil-icon" size={18} />
                  </div>
                }
                content={<ShippingMethod shippingData={submissionData.orderOptionsConfiguration} />}
              />
            )} */}
            {/* <OrderCart
              title="Payment Information"
              // editIcon={<Pencil className="pencil-icon" size={18} />}
              content={<PaymentMethod data={submissionData} />}
            /> */}
          </div>
          <CheckoutButtons
            current={current}
            next={next}
            prev={prev}
            handleSubmit={handleOpenThankYouModal}
            buttonText={'SUBMIT'}
            stepOrder={stepKey}
          />
        </>
      ) : (
        <ThankYou
          handleCloseCheckout={handleCloseCheckout}
          data={filteredItems}
        />
      )}
    </>
  );
};

export default OrderSummary;
