import React from 'react';

const InputField = ({ label, placeholder, unit, required, name, value, handleChange }) => {
  return (
    <div className="firstStep__form-inp">
      <label htmlFor="librarySize" className="firstStep__form-label">
        {label} {required === true && <span>*</span>}
      </label>
      <div className="firstStep__form-block">
        <input
          type="text"
          name={name}
          required
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
        />
        <div className="firstStep__form-end">{unit}</div>
      </div>
    </div>
  );
};

export default InputField;
