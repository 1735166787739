import React from 'react';
import './ServiceProvider.scss';

import { Col, Row } from 'antd';

const SampleSubmission = ({ item }) => {
  return (
    <div className="service-provider">
      <div className="order-details__divider" />
      {item?.sampleNumber && (
        <Row justify="space-between">
          <Col>Sample Number</Col>
          <Col>{item?.sampleNumber}</Col>
        </Row>
      )}

      {item?.organism && (
        <Row justify="space-between">
          <Col>Organism</Col>
          <Col>{item.organism}</Col>
        </Row>
      )}

      {item?.sampleName && (
        <Row justify="space-between">
          <Col>Sample Name</Col>
          <Col>{item.sampleName}</Col>
        </Row>
      )}

      {item?.sampleType && (
        <Row justify="space-between">
          <Col>Sample Type</Col>
          <Col>{item.sampleType}</Col>
        </Row>
      )}

      {item?.tubeBarcode && (
        <Row justify="space-between">
          <Col>Tube Barcode</Col>
          <Col>{item.tubeBarcode}</Col>
        </Row>
      )}

      {item?.volume && (
        <Row justify="space-between">
          <Col>Volume (uL)</Col>
          <Col>{item.volume}</Col>
        </Row>
      )}

      {item?.volumeUnit && (
        <Row justify="space-between">
          <Col>Volume Unit</Col>
          <Col>{item.volumeUnit}</Col>
        </Row>
      )}

      {item?.barcodeLength && (
        <Row justify="space-between">
          <Col>Barcode Length(bp)</Col>
          <Col>{item.barcodeLength}</Col>
        </Row>
      )}

      {item?.barcodeType && (
        <Row justify="space-between">
          <Col>Paired-End or Single-End Barcode</Col>
          <Col>{item.barcodeType}</Col>
        </Row>
      )}

      {item?.buffer && (
        <Row justify="space-between">
          <Col>Buffer</Col>
          <Col>{item.buffer}</Col>
        </Row>
      )}

      {item?.concentration && (
        <Row justify="space-between">
          <Col>Concentration</Col>
          <Col>{item.concentration}</Col>
        </Row>
      )}

      {item?.concentrationUnit && (
        <Row justify="space-between">
          <Col>Concentration Unit</Col>
          <Col>{item.concentrationUnit}</Col>
        </Row>
      )}

      {item?.libraryPreparationKit && (
        <Row justify="space-between">
          <Col>Library Prep. Kit</Col>
          <Col>{item.libraryPreparationKit}</Col>
        </Row>
      )}

      {item?.libraryQCMethod && (
        <Row justify="space-between">
          <Col>Library QC Method</Col>
          <Col>{item.libraryQCMethod}</Col>
        </Row>
      )}

      {item?.librarySize && (
        <Row justify="space-between">
          <Col>Library Size(bp)</Col>
          <Col>{item.librarySize}</Col>
        </Row>
      )}

      {item?.numberOfSamples && (
        <Row justify="space-between">
          <Col>Samples Pooled</Col>
          <Col>{item.numberOfSamples}</Col>
        </Row>
      )}

      {item?.ratio && (
        <Row justify="space-between">
          <Col>Ratio</Col>
          <Col>{item.ratio}</Col>
        </Row>
      )}

      {item?.quantitationMethod && (
        <Row justify="space-between">
          <Col>Quantitation Method</Col>
          <Col>{item.quantitationMethod}</Col>
        </Row>
      )}
      {item?.quantitationParameters && (
        <Row justify="space-between">
          <Col>
            <b>Quantitation Parameters:</b>
          </Col>
        </Row>
      )}
      {item?.quantitationParameters?.fluorescentAssayBioAnalyser ? (
        <Row justify="space-between">
          <Col>fluorescentAssayBioAnalyser</Col>
          <Col>{item.quantitationParameters.fluorescentAssayBioAnalyser}</Col>
        </Row>
      ) : null}

      {item?.quantitationParameters?.fluorescentAssayFragmentAnalyser ? (
        <Row justify="space-between">
          <Col>fluorescentAssayFragmentAnalyser</Col>
          <Col>{item.quantitationParameters.fluorescentAssayFragmentAnalyser}</Col>
        </Row>
      ) : null}

      {item?.quantitationParameters?.fluorescentAssayTapeStation ? (
        <Row justify="space-between">
          <Col>fluorescentAssayTapeStation</Col>
          <Col>{item.quantitationParameters.fluorescentAssayTapeStation}</Col>
        </Row>
      ) : null}

      {item?.quantitationParameters?.librarySizeBioAnalyser ? (
        <Row justify="space-between">
          <Col>librarySizeBioAnalyser</Col>
          <Col>{item.quantitationParameters.librarySizeBioAnalyser}</Col>
        </Row>
      ) : null}

      {item?.quantitationParameters?.librarySizeFragmentAnalyser ? (
        <Row justify="space-between">
          <Col>librarySizeFragmentAnalyser</Col>
          <Col>{item.quantitationParameters.librarySizeFragmentAnalyser}</Col>
        </Row>
      ) : null}

      {item?.quantitationParameters?.librarySizeTapeStation ? (
        <Row justify="space-between">
          <Col>librarySizeTapeStation</Col>
          <Col>{item.quantitationParameters.librarySizeTapeStation}</Col>
        </Row>
      ) : null}

      {item?.quantitationParameters?.nanodrop ? (
        <Row justify="space-between">
          <Col>nanodrop</Col>
          <Col>{item?.quantitationParameters?.nanodrop}</Col>
        </Row>
      ) : null}
      {item?.quantitationParameters?.qprc ? (
        <Row justify="space-between">
          <Col>qprc</Col>
          <Col>{item.quantitationParameters.qprc}</Col>
        </Row>
      ) : null}
      {item?.quantitationParameters?.qubit ? (
        <Row justify="space-between">
          <Col>qubit</Col>
          <Col>{item.quantitationParameters.qubit}</Col>
        </Row>
      ) : null}
    </div>
  );
};

export default SampleSubmission;
