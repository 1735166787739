import React, { useContext, useState } from 'react';
import { Col, Row, Input } from 'antd';
import { Pencil, ChevronDown, ChevronRight } from 'lucide-react';
import './PaymentCard.scss';
import ButtonWhite from '../../../../../../components/ui/button/buttonWhite/ButtonWhite';
import ButtonGradient from '../../../../../../components/ui/button/ButtonGradient';
import userAccountContext from '../../../../../../components/context/userAccount/userAccountContext';

const PaymentCard = ({
  item,
  key,
  handleEditPersonalInfo,
  editPayment,
  handleCancelEdit,
  handleSaveClick,
}) => {
  const { cardName, setCardName, cardNumber, setCardNamber } = useContext(userAccountContext);
  const [visibleCard, setVisibleCard] = useState(true);
  const handleVisibleCard = () => {
    setVisibleCard(!visibleCard);
  };

  return (
    <div className="pay-card" key={key}>
      <Row justify="space-between" align="middle">
        <Col onClick={handleVisibleCard}>
          <div className="pay-card__header">
            {visibleCard ? (
              <ChevronDown className="collapse-icon" size={18} color="#00B0F0" />
            ) : (
              <ChevronRight className="collapse-icon" size={18} color="#00B0F0" />
            )}
            <p>{item.cardNumber === "string" ? "-" : item.cardNumber}</p>
          </div>
        </Col>
        <Col onClick={handleEditPersonalInfo}>
          <Pencil className="pencil-icon" size={18} color="#00B0F0" />
        </Col>
      </Row>
      {visibleCard && (
        <div className="pay-card__box">
          <Row justify="space-between">
            <Col span={11}>
              <div>
                <p className="personal-title">Cardholder’s Name</p>
                {editPayment && (
                  <Input
                    type="text"
                    value={cardName}
                    onChange={({ target }) => setCardName(target.value)}
                  />
                )}
                {!editPayment && <p className="personal-text">{item.cardName === "string" ? "-" : item.cardName}</p>}
              </div>
            </Col>
            <Col span={12}>
              <div>
                <p className="personal-title">Card Number</p>
                {editPayment && (
                  <Input
                    type="number"
                    value={cardNumber}
                    onChange={({ target }) => setCardNamber(target.value)}
                  />
                )}
                {!editPayment && <p className="personal-text">{item.cardNumber === "string" ? "-" : item.cardNumber}</p>}
              </div>
            </Col>
          </Row>
          {editPayment && (
            <Row justify="space-between">
              <Col span={10}>
                <ButtonWhite
                  text="CANCEL"
                  className="user-cartBox__cancelButton"
                  handleClick={handleCancelEdit}
                />
              </Col>

              <Col span={12}>
                <ButtonGradient text="SAVE" handleClick={handleSaveClick} />
              </Col>
            </Row>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentCard;
