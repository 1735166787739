const PROD_URL = 'https://gravl-backend-kazbh4cv7a-uc.a.run.app';
const DEV_URL = 'https://gravl-backend-dev-hc6pqug3mq-uc.a.run.app';
const DEMO_URL = 'https://gravl-backend-rl2rxrpdca-uc.a.run.app';

export const BACKEND_URL =
  process.env.REACT_APP_ENV === 'DEV'
    ? DEV_URL
    : process.env.REACT_APP_ENV === 'DEMO'
      ? DEMO_URL
      : process.env.REACT_APP_ENV === 'PROD'
        ? PROD_URL
        : 'http://localhost:80';
