import React, { useEffect } from 'react';
import './LoginInfo.scss';

const LoginInfo = ({ isLoading, tokenIsValid, onLogout }) => {
  const [userName, setUserName] = React.useState('');
  const [userLastName, setUserLastName] = React.useState('');
  const [initials, setInitials] = React.useState('');

  useEffect(() => {
    setUserName(localStorage.getItem('user_name') || '');
    setUserLastName(localStorage.getItem('last_name') || '');

    setInitials(
      localStorage.getItem('user_name')?.charAt(0) + localStorage.getItem('last_name')?.charAt(0)
    );
  }, [isLoading, tokenIsValid]);

  const handleLogOut = () => {
    localStorage.removeItem('user_name');
    localStorage.removeItem('last_name');
    localStorage.removeItem('gravl_token');

    document.cookie =
      'gravl_token=; domain=gravl.io; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    onLogout();

    window.location.reload();
  };

  return (
    <div className="login-info__container">
      <div className="login-info"> {initials}</div>
      <div className="login-info__userName">
        {userName} {userLastName}
      </div>
      <div onClick={handleLogOut} className="login-info__logout">
        Log Out
      </div>
    </div>
  );
};

export default LoginInfo;
