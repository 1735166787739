import React, { useEffect, useState } from 'react';
import ButtonWhite from '../../../../../../components/ui/button/buttonWhite/ButtonWhite';
import { FileCheck, FolderUp, Info, Upload, X } from 'lucide-react';
import './FileImport.scss';

const FileImport = ({
  isVisible,
  generateExcelTemplate,
  handleFileUpload,
  uploadFileError,
  setUploadFileError,
}) => {
  const [fileName, setFileName] = useState('');
  const [showError, setShowError] = useState(uploadFileError);

  const handleFileChange = event => {
    const files = event.target.files;

    handleFileUpload(event);
    if (files.length > 0) {
      const fileNames = Array.from(files)
        .map(file => truncateFileName(file.name))
        .join(', ');
      setFileName(fileNames);
    } else {
      setFileName('');
    }
  };

  const truncateFileName = name => {
    const maxLength = 20;
    if (name.length > maxLength) {
      const firstPart = name.substring(0, 2);
      const lastPart = name.substring(name.length - 6);

      return `${firstPart}...${lastPart}`;
    } else {
      return name;
    }
  };

  const handleRemoveFile = () => {
    setFileName('');
  };
  useEffect(() => {
    if (uploadFileError) {
      setShowError(true);

      const timer = setTimeout(() => {
        window.location.reload();
      }, 3000);

      return () => clearTimeout(timer); // Clear the timeout if component unmounts or error state changes
    }
  }, [uploadFileError]);

  return (
    <div className="firstStep-fileBox">
      {!isVisible && (
        <div className="firstStep-fileBox__import">
          <ButtonWhite
            text="UPLOAD QC FILES"
            icon={<FolderUp className="quote-icon" size={20} />}
          />
        </div>
      )}
      {showError ? (
        <p className="import__error">Your file has error. Check required entries in inputs.</p>
      ) : !fileName ? (
        <>
          <div className="firstStep-fileBox__import">
            <form className="import">
              <label htmlFor="file-upload" className="btn-white">
                <Upload className="quote-icon" size={20} /> IMPORT FLAT FILE
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                accept=".xlsx, .xls"
                className="file-upload"
              />
            </form>
          </div>
          {!isVisible && (
            <div className="firstStep-fileBox__import-template">
              <Info className="info-icon" size={24} />
              <p>
                In order to prepare the file properly, please use our
                <span onClick={generateExcelTemplate}>&nbsp;template blank file.</span>
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="import-box">
            <div className="import-box__icon">
              <FileCheck className="info-icon" size={20} color="#09C7BE" />
            </div>
            <div>
              <p className="import-box__text">Imported Flat File</p>
              <p className="import-box__name">{fileName}</p>
            </div>
            <div onClick={handleRemoveFile} className="import-box__remove">
              <X className="info-icon" size={20} color="#E83434" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FileImport;
