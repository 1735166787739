import { Col, Row } from 'antd';
import React from 'react';
import logo from '../../assets/images/gravl.png';

import './Logo.scss';

const Logo = () => {
  return (
    <Row className="logo" justify="space-between">
      <Col>
        <img src={logo} alt="logo" className="logo__name" />
      </Col>
    </Row>
  );
};

export default Logo;
