import React, { useContext, useState } from 'react';
import { ChevronDown, ChevronRight, Trash2 } from 'lucide-react';
import './TableDnaRna.scss';

import InputField from '../InputField/InputField';
import submissionContext from '../../../../../../components/context/submissionContext/submissionContext';
import DnaInputSettings from './DnaInputSettings';

const TableDnaRna = ({
  dnaSample,
  setDnaSample,
  handleAddRow,
  handleDeleteRow,
  handleSubmitFirstStep,
  handleChange,
}) => {

  const [visibleRows, setVisibleRows] = useState({});
  const {inputDnaValue} = useContext(submissionContext);


  const toggleVisibleRow = index => {
    setVisibleRows({
      ...visibleRows,
      [index]: !visibleRows[index],
    });
  };
  const isRowCompleted = row => {
    return Object.values(row).every(val => {
      if (typeof val === 'string') {
        return val.trim() !== '';
      }

      return true;
    });
  };

  const generateUniqueKey = row => {
    return `${row.id}`;
  };

  return (
    <div>
      <div className="dna-table">
        <table className="dna-table__table">
          <tbody>
            <tr>
              <th className="dna-table__th">#</th>
              <th className="dna-table__th">
                <div className="dna-table__header">
                  <div className="dna-table__header-sampleName">Sample Name</div>
                  <div className="dna-table__header-primerName">Tube Barcode</div>
                  <div className="dna-table__header-molarity">Sample Type</div>

                  <div className="dna-table__header-instructions">Volume (uL)</div>
                  <div className="dna-table__header-volume">Concentration</div>
                  <div className="dna-table__header-unit">Concentration Unit</div>
                  <div className="dna-table__header-method">Quantitation Method</div>

                  <div className="dna-table__header-ratio">260/280 Ratio</div>
                  <div className="dna-table__header-buffer">Buffer</div>
                  <div className="dna-table__header-organism">Organism</div>
                </div>
              </th>
            </tr>
            {dnaSample.map((row, index) => {
              const uniqueKey = generateUniqueKey(row);

              return (
                <tr
                  key={uniqueKey}
                  // className={isRowCompleted(row) ? "row-complete" : ""}
                >
                  <td className="dna-table__id">{index + 1}</td>
                  <td>
                    <div className="dna-table__inputsInfo">
                      <div
                        className={isRowCompleted(row) ? 'row-complete' : 'dna-table__inputsRow'}
                      >
                        <div onClick={() => toggleVisibleRow(index)} className="dna-table__icon">
                          {visibleRows[index] ? (
                            <ChevronDown className="dna-table__icon-arrow" />
                          ) : (
                            <ChevronRight className="dna-table__icon-arrow" />
                          )}
                        </div>

                        <DnaInputSettings
                          row={row}
                          setDnaSample={setDnaSample}
                          index={index}
                          dnaSample={dnaSample}
                          isRowCompleted={isRowCompleted}
                        />

                        <Trash2
                          className="delete-icon"
                          onClick={() => handleDeleteRow(row.id)}
                          color="#002060"
                          width={18}
                        />
                      </div>
                      {!visibleRows[index] && (
                        <div className="firstStep__formSection">
                          {(dnaSample[index]?.quantitationMethod === 'Nanodrop' ||
                            dnaSample[index]?.quantitationMethod === 'Qubit') && (
                            <h3 className="firstStep__formSection-title">
                              Quantification Parameters
                            </h3>
                          )}

                          <form
                            className="firstStep__form"
                            onSubmit={e => handleSubmitFirstStep(e, index)}
                          >
                            {dnaSample[index]?.quantitationMethod === 'Nanodrop' && (
                              <InputField
                                label="Nanodrop"
                                placeholder="Enter Nanodrop"
                                unit="bp"
                                required={true}
                                name="nanodrop"
                                value={inputDnaValue[index]?.nanodrop}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}

                            {dnaSample[index]?.quantitationMethod === 'Qubit' && (
                              <InputField
                                label="Qubit (nguL)"
                                placeholder="Enter Library Size"
                                unit="nguL"
                                required={true}
                                name="qubit"
                                value={inputDnaValue[index]?.qubit}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}
                          </form>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div onClick={handleAddRow} className="dna-table__addRow">
        + SAMPLES
      </div>
    </div>
  );
};

export default TableDnaRna;
