import React from 'react';
import './TeamOnfo.scss';
import { ChevronDown, ChevronRight } from 'lucide-react';
import TeamDetails from './teamDetails/TeamDetails';
import ReactGA from "react-ga4"

const TeamInfo = ({ visibleTeam, handleVisibleTeam, orders }) => {
  visibleTeam && ReactGA.event({
    category: "Tab Open",
    action: "Team Information",
    label: "Orders Page",
  });

  return (
    <div className="team-details">
      <div className="team-details__main" onClick={handleVisibleTeam}>
        <div className="team-details__main link">
          <div className="team-details__main link-pointer">
            {visibleTeam ? <ChevronDown color="#36C6E6" /> : <ChevronRight color="#36C6E6" />}
            <p className="team-details__main link-text">
              Team Information ({orders.lab.team.length})
            </p>
          </div>
        </div>
      </div>

      {visibleTeam && (
        <div className="team-details__list">
          {orders.lab.team.map((member, index) => (
            <TeamDetails key={index} member={member} />
          ))}
        </div>
      )}
      <div className="team-details__divider" />
    </div>
  );
};

export default TeamInfo;
