import React from 'react';
import './ButtonWhite.scss';

const ButtonWhite = ({ text, icon, handleClick, disabled }) => {
  const btnClassName = disabled ? 'btn-white btn-white--disabled' : 'btn-white';

  return (
    <button className={btnClassName} onClick={handleClick} disabled={disabled}>
      {icon}
      {text}
    </button>
  );
};

export default ButtonWhite;
