import React from 'react';
import { Switch } from 'antd';
import { Trash2 } from 'lucide-react';
import './IndicesSection.scss';
import RadioSelection from '../radioSelection/RadioSelection';

import ImportIndices from './importIndicesFile/ImportIndices';
import * as XLSX from 'xlsx';
const IndicesSection = ({
  primerData,
  selectedOption,
  handleSwitchChange,
  setSelectedOption,
  indexType,
  handleAddRow,
  handleDeleteRow,
  addLibrary,
  setAddLibrary,
  handleIndexChange,
}) => {
  const generateExcelTemplate = () => {
    let headers;

    headers = addLibrary;

    if (!headers || !headers[0]) {
      console.error('Headers are undefined or empty');

      return;
    }
    headers = Object?.keys(headers[0]);

    if (headers[0] === 'id') {
      headers = headers.slice(1);
    }

    const data = [headers];

    for (let i = 0; i < 3; i++) {
      // Creating 5 rows
      const row = [];

      data.push(row);
    }

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');

    XLSX.writeFile(workbook, 'template.xlsx');
  };
  const handleFileUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });

      const headers = excelData.shift();

      let headersToCheck;
      let sampleType;
      //check if header is correct
      headersToCheck = addLibrary;
      sampleType = 'Library';

      if (headersToCheck) {
        headersToCheck = Object.keys(headersToCheck[0]);

        if (headersToCheck[0] === 'id') {
          headersToCheck = headersToCheck.slice(1);
        }
      } else {
        console.error('Headers to check are invalid.');

        return;
      }

      headers.every((header, index) => {
        if (header !== headersToCheck[index]) {
          return false;
        } else {
          return true;
        }
      });

      const validRows = excelData.filter(row => row.some(cell => cell !== undefined));

      for (let row = 0; row < excelData.length; row++) {
        if (sampleType === 'Library') {
          setAddLibrary(prev => {
            const newRows = validRows
              .map((row, idx) => {
                const parsedRow = row
                  .map((cell, index) => [headers[index], cell])
                  .filter(value => Boolean(value));

                if (parsedRow.every(value => value)) {
                  return {
                    id: prev.length + 1 + idx,
                    ...Object?.fromEntries(parsedRow),
                  };
                } else {
                  return [];
                }
              })
              .flat();

            return [...newRows];
          });
        } else if (sampleType === 'DNAorRNA') {
          // console.log('DNAorRNA');
        } else if (sampleType === 'EnvironmentalSample') {
          // console.log('EnvironmentalSample');
        }
      }

      // Pass the parsed data to the parent component
      // onDataImport(excelData);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="indices">
      <h2 className="indices__titles">Indices & UMIs</h2>

      <div className="indices__wrapp">
        <div className="indices__wrapp switcher">
          <p className="indices__text">Do you have indices?</p>

          <div className="indices__option">
            <div className={selectedOption ? 'no' : 'yes'} onClick={() => setSelectedOption(false)}>
              No
            </div>
            <Switch
              defaultChecked={selectedOption}
              onChange={handleSwitchChange}
              className="indices__switch"
            />
            <div className={selectedOption ? 'yes' : 'no'} onClick={() => setSelectedOption(true)}>
              Yes
            </div>
          </div>
        </div>
      </div>
      <div className="indices__radioWrapp">
        <RadioSelection
          name="indexType"
          value="singleIndex"
          checked={indexType === 'singleIndex'}
          onChange={handleIndexChange}
          label="Single Index"
        />

        <RadioSelection
          name="indexType"
          value="DualIndex"
          checked={indexType === 'DualIndex'}
          onChange={handleIndexChange}
          label="Dual Index"
        />

        <RadioSelection
          name="indexType"
          value="EnableUmi"
          checked={indexType === 'EnableUmi'}
          onChange={handleIndexChange}
          label="Enable UMI"
        />
      </div>
      {primerData?.indices === true ? (
        <>
          {addLibrary.map((item, index) => (
            <div key={item.id} className="indices__inputs">
              <input
                placeholder="Library Name"
                type="text"
                value={item.name}
                onChange={e => {
                  const updatedRows = [...addLibrary];
                  updatedRows[index].name = e.target.value;
                  setAddLibrary(updatedRows);
                }}
              />
              <input
                placeholder="Enter Index (6-16 characters)"
                type="text"
                value={item.size}
                onChange={e => {
                  const updatedRows = [...addLibrary];
                  updatedRows[index].size = e.target.value;
                  setAddLibrary(updatedRows);
                }}
              />
              <Trash2
                className="delete-icon"
                onClick={() => handleDeleteRow(item.id)}
                color="#002060"
                width={18}
              />
            </div>
          ))}
          <div onClick={handleAddRow} className="indices__addRow">
            + Add another
          </div>
        </>
      ) : null}
      <ImportIndices
        generateExcelTemplate={generateExcelTemplate}
        handleFileUpload={handleFileUpload}
      />
    </div>
  );
};

export default IndicesSection;
