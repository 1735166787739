import React, { useEffect, useState } from 'react';
import ButtonWhite from '../../../../../../components/ui/button/buttonWhite/ButtonWhite';
import { FileCheck, FolderUp, Info, Upload, X } from 'lucide-react';
import './CobiomicFileImport.scss';
import { downloadFile } from '../../../../../../components/utils/downloadUtils';

const CobiomicFileImport = ({
  setFilesUploaded,
  templateFile,
  setTemplateFile,
  manifestFile,
  setManifestFile,
}) => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (templateFile && manifestFile) {
      setFilesUploaded(true);
    } else {
      setFilesUploaded(false);
    }
  }, [templateFile, manifestFile]);

  const handleTemplateChange = (e) => {
    setTemplateFile(e.target.files[0]);
  };

  const handleTemplateRemove = () => {
    setTemplateFile(null);
  };

  const handleManifestChange = (e) => {
    // console.log(e.target.files[0]);
    setManifestFile(e.target.files[0]);
  };

  const handleManifestRemove = () => {
    setManifestFile(null);
  };

  const onDownloadTemaplate = () => {
    const SamplesCollectionFormName =
      'COBIOMIC_Samples_collection_Form.pdf';
    const SamplesManifestName = 'Sample_Manifest_Template.xlsx';

    downloadFile(SamplesCollectionFormName);

    setTimeout(() => {
      downloadFile(SamplesManifestName);
    }, 50);
  };

  return (
    <div className="firstStep-fileBox">
      {showError ? (
        <p className="import__error">
          Your file has error. Check required entries in inputs.
        </p>
      ) : (
        <>
          <div className="firstStep-fileBox__import">
            {templateFile ? (
              <>
                <div className="cobiomic-import-box">
                  <div className="cobiomic-import-box__icon">
                    <FileCheck
                      className="info-icon"
                      size={20}
                      color="#09C7BE"
                    />
                  </div>
                  <div className="cobiomic-import-box__text-box">
                    <p className="cobiomic-import-box__text">
                    Uploaded sample collection 
                    </p>
                    <p className="cobiomic-import-box__name">
                      {templateFile?.name.length > 25
                        ? templateFile?.name.substring(0, 25) + '...'
                        : templateFile?.name}
                    </p>
                  </div>
                  <div
                    onClick={handleTemplateRemove}
                    className="cobiomic-import-box__remove"
                  >
                    <X className="info-icon" size={20} color="#E83434" />
                  </div>
                </div>
              </>
            ) : (
              <form className="cobiomic-import">
                <label htmlFor="template-upload" className="btn-white">
                  <Upload className="quote-icon" size={20} />
                  Upload sample collection 
                </label>
                <input
                  id="template-upload"
                  type="file"
                  onChange={handleTemplateChange}
                  accept=".pdf"
                  className="template-upload"
                />
              </form>
            )}

            {manifestFile ? (
              <>
                <div className="cobiomic-import-box">
                  <div className="cobiomic-import-box__icon">
                    <FileCheck
                      className="info-icon"
                      size={20}
                      color="#09C7BE"
                    />
                  </div>
                  <div className="cobiomic-import-box__text-box">
                    <p className="cobiomic-import-box__text">
                    Uploaded sample manifest
                    </p>
                    <p className="cobiomic-import-box__name">
                      {manifestFile?.name.length > 25
                        ? manifestFile?.name.substring(0, 25) + '...'
                        : manifestFile?.name}
                    </p>
                  </div>
                  <div
                    onClick={handleManifestRemove}
                    className="cobiomic-import-box__remove"
                  >
                    <X className="info-icon" size={20} color="#E83434" />
                  </div>
                </div>
              </>
            ) : (
              <form className="cobiomic-import">
                <label htmlFor="manifest-upload" className="btn-white">
                  <Upload className="quote-icon" size={20} />
                  Upload sample manifest
                </label>
                <input
                  id="manifest-upload"
                  type="file"
                  onChange={handleManifestChange}
                  accept=".xlsx, .xls"
                  className="manifest-upload"
                />
              </form>
            )}
          </div>
          <div className="firstStep-fileBox__import-template">
            <Info className="info-icon" size={24} />
            <p className="firstStep-fileBox__import-template-text">
              This service supports only template input, so please download our
              <span onClick={onDownloadTemaplate}>
                &nbsp;template blank files.
              </span>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default CobiomicFileImport;
