import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Checkbox, Input } from 'antd';
import { BACKEND_URL } from '../../services/serverData';
import sign from '../../components/assets/images/log.jpg';
import { InputField } from '../../components/ui/inputs/InputField';
import Error from '../../components/Error/Error';
import ButtonGradient from '../../components/ui/button/ButtonGradient';
import { KeyRound } from 'lucide-react';
import './Login.scss';
import profileInfoContext from '../../components/context/profileInfoContext';
import ReactGA from "react-ga4"


const Login = ({ onSuccesfullLogin }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [passwordOwner, setPasswordOwner] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);
  const [rememberToken, setRememberToken] = useState(false);
  const { setUserProfile, setToken } = useContext(profileInfoContext);

  ReactGA.send({
    hitType: "pageview",
    page: `/login`,
    title: "Login Page"
  })

  const handleSave = () => {
    if (!emailAddress?.includes('@')) {
      alert('Please enter a valid email address');

      return;
    }

    const data = {
      email: emailAddress,
      password: passwordOwner,
      remember: rememberToken,
    };

    axios
      .post(`${BACKEND_URL}/auth/login`, data)
      .then(response => {
        setErrorMessages([]);

        localStorage.setItem('gravl_token', response.data.token);
        setToken(response.data.token);

        localStorage.setItem('user_name', response.data.profile.firstName);
        localStorage.setItem('last_name', response.data.profile.lastName);

        setUserProfile(response.data.profile);
        onSuccesfullLogin();
      })
      .catch(error => {
        console.error('Error:', error);
        if (error.response.status === 404) {
          let errorShowMessage = error?.response?.data?.message;
          setErrorMessages(errorShowMessage);
        }

        setErrorMessages(error?.response?.data?.message);
      });
  };
  const Label = ({ text }) => {
    return <p className="sign-modal__label">{text}</p>;
  };

  return (
    <div className="sign-modal-container">
      <div className="sign-modal">
        <div className="sign-modal__header">
          <img src={sign} alt="logo" />
          <h3 className="sign-modal__title">Sign in to Your Account</h3>
          <p className="sign-modal__provide">Please provide your sign in data below.</p>
        </div>
        <div className="sign-modal__form">
          <div className="sign-modal__input-container">
            <Label text="Email" />
            <InputField
              type="text"
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
              placeholder="Please enter here..."
            />
          </div>
          <div className="sign-modal__input-container">
            <Label text="Password" />
            <Input.Password
              type="password"
              value={passwordOwner}
              onChange={({ target }) => setPasswordOwner(target.value)}
              placeholder="Please enter here..."
              className="sign-modal__passInput"
            />
          </div>
        </div>
        <Error errorMessages={errorMessages} />
        <div className="sign-modal__checkbox-container">
          <Checkbox onChange={e => setRememberToken(e.target.checked)} checked={rememberToken}>
            Remember me for 7 days
          </Checkbox>
        </div>
        <div className="sign-modal__button-container">
          <ButtonGradient
            text="Sign In"
            icon={<KeyRound className="user-icon" size={20} />}
            handleClick={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
