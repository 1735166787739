import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import { BACKEND_URL } from '../../../../../services/serverData';
import StepTable from './table/StepTable';

import './SecondStep.scss';
import CheckoutButtons from '../../buttons/CheckoutButtons';
import ReactGA from "react-ga4"

const SecondStep = ({ current, next, prev,stepKey }) => {
  const { orderId: id, submitId: subId } = useParams();
  ReactGA.send({
    hitType: "pageview",
    page: `/submission/${id}/${subId}`,
    title: "Data Deliverables Page"
  })


  const orderId = id;
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem('gravl_token');
  const [deliverableData, setDeliverableData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState(false);
  const [selectedOptionBam, setSelectedOptionBam] = useState(false);
  const [selectedFastMulti, setSelectedFastMulti] = useState(false);
  const [selecteRowFile, setSelecteRowFile] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/admin/services/${orderId}/configuration/deliverables`
        );

        setDeliverableData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orderId]);

  const handleSubmitSecondStep = async e => {
    e.preventDefault();

    const inputValues = {
      bamAlignment: { chosen: selectedOption },
      bamDemultiplexed: { chosen: selectedOption1 },
      bamMultiplexed: { chosen: selectedOptionBam },
      fastqDemultiplexed: { chosen: selectedFastMulti },
      rawFiles: { chosen: selecteRowFile },
    };
    try {
      const response = await axios.put(
        `${BACKEND_URL}/admin/orders/${orderId}/Deliverables`,
        inputValues,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            orderId: orderId,
          },
        }
      );
      message.success('Data Deliverables updated successfully!');
      next();
      // console.log('Response:', response);
    } catch (error) {
      console.error('Error:', error);
      message.error(
        error.response?.data?.message || 'Failed to update Data Deliverables. Please try again.'
      );
    }
  };

  return (
    <>
      <div className="secondStep">
        <div className="secondStep__inputs">
          <p className="secondStep__text">Select the data format you would like</p>
          <StepTable
            deliverableData={deliverableData}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            selectedOption1={selectedOption1}
            setSelectedOption1={setSelectedOption1}
            selectedOptionBam={selectedOptionBam}
            setSelectedOptionBam={setSelectedOptionBam}
            selectedFastMulti={selectedFastMulti}
            setSelectedFastMulti={setSelectedFastMulti}
            selecteRowFile={selecteRowFile}
            setSelecteRowFile={setSelecteRowFile}
            isLoading={isLoading}
          />
        </div>
        {/* <CheckOutTotals /> */}
      </div>
      <CheckoutButtons
        current={current}
        next={next}
        prev={prev}
        handleSubmit={handleSubmitSecondStep}
        disabled={false}
        buttonText={'CONTINUE'}
        stepKeySecond={stepKey}
      />
    </>
  );
};

export default SecondStep;
