import React from 'react';
import ReactGA from "react-ga4"

const Messenger = () => {
  ReactGA.send({
    hitType: "pageview",
    page: `/messenger`,
    title: "Messenger Page"
  })

  return <div className="user-cart">Coming soon...</div>;
};

export default Messenger;
