import React from 'react';
import TableFirstQaStep from '../tableFirstQa/TableFirstQaStep';
import ButtonWhite from '../../../../../../components/ui/button/buttonWhite/ButtonWhite';
import TableDnaRna from '../tableDnaRna/TableDnaRna';
import TableEnvironmental from '../tableEnvironmental/TableEnvironmental';
import FileImport from '../importFile/FileImport';
import { ChevronsDownUp } from 'lucide-react';

function DefaultCard({
  qaData,
  isTableExpanded,
  setIsTableExpanded,
  primers,
  setRowsPrimers,
  handleAddRow,
  handleDeleteRow,
  handleSubmitFirstStep,
  handleChange,
  dnaSample,
  setDnaSample,
  handleAddRowDnaSample,
  handleDeleteRowDnaSample,
  handleChangeDnaSample,
  envirTable,
  setEnvirTable,
  handleAddEnvirTable,
  handleDeleteEnvirTable,
  handleChangeEnvirTable,
  generateExcelTemplate,
  handleFileUpload,
  uploadFileError,
  setUploadFileError,
}) {
  return (
    <>
      <h2 className="firstStep__title">
        Sample Information (
        {qaData?.sampleType === 'Library'
          ? 'Library'
          : qaData?.sampleType === 'DNAorRNA'
            ? 'DNA or RNA'
            : 'Environmental Sample'}
        )
      </h2>

      <div className="firstStep__scalling">
        <p className="firstStep__text">
          Enter the number of samples you are submitting and the QC criteria requested by the
          facility.
        </p>
        <div className="firstStep__scalBtn">
          <ButtonWhite
            text={isTableExpanded ? 'EXPAND ' : 'BACK TO DEFAULT VIEW'}
            icon={<ChevronsDownUp className="quote-icon" size={20} />}
            handleClick={() => setIsTableExpanded(!isTableExpanded)}
          />
        </div> 
      </div>
      {qaData?.sampleType === 'Library' && (
        <TableFirstQaStep
          rows={primers}
          setRows={setRowsPrimers}
          handleAddRow={handleAddRow}
          handleDeleteRow={handleDeleteRow}
          handleSubmitFirstStep={handleSubmitFirstStep}
          handleChange={handleChange}
        />
      )}
      {qaData?.sampleType === 'DNAorRNA' && (
        <TableDnaRna
          dnaSample={dnaSample}
          setDnaSample={setDnaSample}
          handleAddRow={handleAddRowDnaSample}
          handleDeleteRow={handleDeleteRowDnaSample}
          handleSubmitFirstStep={handleSubmitFirstStep}
          handleChange={handleChangeDnaSample}
        />
      )}
      {qaData?.sampleType === 'EnvironmentalSample' && (
        <TableEnvironmental
          envirTable={envirTable}
          setEnvirTable={setEnvirTable}
          handleAddRow={handleAddEnvirTable}
          handleDeleteRow={handleDeleteEnvirTable}
          handleSubmitFirstStep={handleSubmitFirstStep}
          handleChange={handleChangeEnvirTable}
        />
      )}
      <FileImport
        generateExcelTemplate={generateExcelTemplate}
        handleFileUpload={handleFileUpload}
        uploadFileError={uploadFileError}
        setUploadFileError={setUploadFileError}
      />
    </>
  );
}

export default DefaultCard;
