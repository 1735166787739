import React from 'react';
import './OrdetCart.scss';
const OrderCart = ({ title, editIcon, content }) => {
  return (
    <div className="order-cart">
      <div className="order-cart__edit">
        <h2 className="order-cart__title">{title}</h2>
        <div className="order-cart__icon">{editIcon}</div>
      </div>
      {content}
    </div>
  );
};

export default OrderCart;
