import React, { useContext } from 'react';
import submissionContext from '../../../../../../components/context/submissionContext/submissionContext';

const SampleInputSettings = ({ row, setRows, index, rows }) => {
  const { qaData } = useContext(submissionContext);
  const options = ['RNA Sequencing Library', 'DNA Sequencing Library'];
  const optionsConcentrationUnit = ['nM', 'ng/ul'];

  const optionsMethodQc = ['Bioanalyzer', 'TapeStation', 'Fragment Analyzer', 'Other'];

  const optionsPair = ['Paired-End (PE)', 'Single-End (SE)'];

  const optionsMethod = [];
  if (qaData?.quantitationParameters?.qPCR === true) {
    optionsMethod.push('qPCR');
  }
  if (qaData?.quantitationParameters?.Nanodrop === true) {
    optionsMethod.push('Nanodrop');
  }
  if (qaData?.quantitationParameters?.Qubit === true) {
    optionsMethod.push('Qubit');
  }
  if (qaData?.quantitationParameters?.BioAnalyzer === true) {
    optionsMethod.push('Bioanalyzer');
  }
  if (qaData?.quantitationParameters?.TapeStation === true) {
    optionsMethod.push('TapeStation');
  }
  if (qaData?.quantitationParameters?.FragmentAnalyser === true) {
    optionsMethod.push('Fragment Analyzer');
  }

  optionsMethod.push('Other');

  return (
    <>
      <input
        id="librarySize"
        placeholder="Sample name..."
        type="text"
        value={row.sampleName}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].sampleName = e.target.value;
          setRows(updatedRows);
        }}
      />
      <input
        id="librarySize"
        placeholder="Tube Barcode..."
        type="text"
        value={row.tubeBar}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].tubeBar = e.target.value;
          setRows(updatedRows);
        }}
      />
      <select
        id="selectSize"
        value={row.sampleType}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].sampleType = e.target.value;
          setRows(updatedRows);
        }}
      >
        <option value="" disabled>
          Select...
        </option>

        {options.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        id="librarySize"
        placeholder="Library Preparation..."
        type="text"
        value={row.libPrapKit}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].libPrapKit = e.target.value;
          setRows(updatedRows);
        }}
      />

      <input
        id="librarySize"
        placeholder="Volume..."
        type="tel"
        value={row.volumeUl}
        onChange={e => {
          const newValue = e.target.value;

          // Remove all non-digit characters to ensure only numbers are allowed
          const sanitizedValue = newValue.replace(/\D/g, '');

          const updatedRows = [...rows];
          updatedRows[index].volumeUl = sanitizedValue; // Update with the sanitized value
          setRows(updatedRows); // Update state
        }}
      />
      <input
        id="librarySize"
        placeholder="Concentration..."
        type="tel"
        value={row.concentration}
        onChange={e => {
          const newValue = e.target.value;

          // Remove all non-digit characters to ensure only numbers are allowed
          const sanitizedValue = newValue.replace(/\D/g, '');
          const updatedRows = [...rows];
          updatedRows[index].concentration = sanitizedValue;
          setRows(updatedRows);
        }}
      />
      <select
        id="selectSize"
        value={row.concentrationUnit}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].concentrationUnit = e.target.value;
          setRows(updatedRows);
        }}
      >
        <option value="" disabled>
          Select...
        </option>

        {optionsConcentrationUnit.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>
      <select
        id="selectSize"
        value={row.quantitationMethod}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].quantitationMethod = e.target.value;
          setRows(updatedRows);
        }}
      >
        <option value="" disabled>
          Select ...
        </option>

        {optionsMethod.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        id="librarySize"
        placeholder="Library Size(bp)..."
        type="tel"
        value={row.libBp}
        onChange={e => {
          const newValue = e.target.value;

          // Remove all non-digit characters to ensure only numbers are allowed
          const sanitizedValue = newValue.replace(/\D/g, '');
          const updatedRows = [...rows];
          updatedRows[index].libBp = sanitizedValue;
          setRows(updatedRows);
        }}
      />
      <select
        id="selectSize"
        value={row.libraryQCMethod}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].libraryQCMethod = e.target.value;
          setRows(updatedRows);
        }}
      >
        <option value="" disabled>
          Select...
        </option>

        {optionsMethodQc.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        id="librarySize"
        placeholder="Buffer..."
        type="text"
        value={row.buffer}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].buffer = e.target.value;
          setRows(updatedRows);
        }}
      />
      <input
        id="librarySize"
        placeholder="Organism..."
        type="text"
        value={row.organism}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].organism = e.target.value;
          setRows(updatedRows);
        }}
      />
      <select
        id="selectSize"
        value={row.barcodeType}
        onChange={e => {
          const updatedRows = [...rows];
          updatedRows[index].barcodeType = e.target.value;
          setRows(updatedRows);
        }}
      >
        <option value="" disabled>
          Select ...
        </option>

        {optionsPair.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        id="librarySize"
        placeholder="Barcode Length(bp)..."
        type="tel"
        value={row.barCodeLength}
        onChange={e => {
          const newValue = e.target.value;

          // Remove all non-digit characters to ensure only numbers are allowed
          const sanitizedValue = newValue.replace(/\D/g, '');
          const updatedRows = [...rows];
          updatedRows[index].barCodeLength = sanitizedValue;
          setRows(updatedRows);
        }}
      />

      <input
        id="librarySize"
        placeholder="Samples Pooled..."
        type="tel"
        value={row.samPool}
        onChange={e => {
          const newValue = e.target.value;

          // Remove all non-digit characters to ensure only numbers are allowed
          const sanitizedValue = newValue.replace(/\D/g, '');
          const updatedRows = [...rows];
          updatedRows[index].samPool = sanitizedValue;
          setRows(updatedRows);
        }}
      />
    </>
  );
};

export default SampleInputSettings;
