import React, { useState } from 'react';
import { Col, Row, Switch } from 'antd';
import '../personal/PersonalInfo.scss';

const NotificationUser = () => {
  const [selectedOption, setSelectedOption] = useState(false);
  const [selectPo, setSelectPo] = useState(false);
  const [selectMessage, setSelectMessage] = useState(false);
  const [selectCancel, setSelectCancel] = useState(false);

  const handleSwitchChange = isChecked => {
    setSelectedOption(isChecked);
  };

  const handleSwitchPo = isChecked => {
    setSelectPo(isChecked);
  };

  const handleSwitchMessage = isChecked => {
    setSelectMessage(isChecked);
  };
  const handleSwitchCancel = isChecked => {
    setSelectCancel(isChecked);
  };

  return (
    <div className="personal">
      <Row>Notify me when:</Row>
      <Row justify="space-between">
        <Col>
          <b>Sampling Info is Required</b>
        </Col>
        <Col>
          <div className="switcher">
            <div className="switcher__option">
              <div
                className={selectedOption ? 'no' : 'yes'}
                onClick={() => setSelectedOption(false)}
              >
                No
              </div>
              <Switch
                defaultChecked={selectedOption}
                onChange={handleSwitchChange}
                className="switcher__switch"
              />
              <div
                className={selectedOption ? 'yes' : 'no'}
                onClick={() => setSelectedOption(true)}
              >
                Yes
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="space-between">
        {' '}
        <Col>
          <b>PO has been successfully reviewed</b>
        </Col>{' '}
        <Col>
          <div className="switcher">
            <div className="switcher__option">
              <div className={selectPo ? 'no' : 'yes'} onClick={() => setSelectPo(false)}>
                No
              </div>
              <Switch
                defaultChecked={selectPo}
                onChange={handleSwitchPo}
                className="switcher__switch"
              />
              <div className={selectPo ? 'yes' : 'no'} onClick={() => setSelectPo(true)}>
                Yes
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <b>I receive a new message</b>
        </Col>
        <Col>
          {' '}
          <div className="switcher">
            <div className="switcher__option">
              <div className={selectMessage ? 'no' : 'yes'} onClick={() => setSelectMessage(false)}>
                No
              </div>
              <Switch
                defaultChecked={selectMessage}
                onChange={handleSwitchMessage}
                className="switcher__switch"
              />
              <div className={selectMessage ? 'yes' : 'no'} onClick={() => setSelectMessage(true)}>
                Yes
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <b>Order has been cancelled</b>
        </Col>
        <Col>
          {' '}
          <div className="switcher">
            <div className="switcher__option">
              <div className={selectCancel ? 'no' : 'yes'} onClick={() => setSelectCancel(false)}>
                No
              </div>
              <Switch
                defaultChecked={selectCancel}
                onChange={handleSwitchCancel}
                className="switcher__switch"
              />
              <div className={selectCancel ? 'yes' : 'no'} onClick={() => setSelectCancel(true)}>
                Yes
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationUser;
