import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonWhite from '../../../../components/ui/button/buttonWhite/ButtonWhite';
import ButtonGradient from '../../../../components/ui/button/ButtonGradient';

const CheckoutButtons = ({
  current,
  next,
  prev,
  buttonText,
  disabled,
  handleSubmit,
  handleSubmitCobiomic,
  stepKeySamplwSubmition,
  dataSampleFirst,
  stepPrimers,
  dataPrimers,
  stepKeySecond,
  stepFinish,
  stepOrder
}) => {
  const navigate = useNavigate();

  return (
    <div className="checkout__btnWrapp">
      <div className="checkout__btnWrapp-prev">
        {stepKeySamplwSubmition && (
          <ButtonWhite text="CANCEL" handleClick={() => navigate('/orders')} />
        )}
        {(stepPrimers || stepKeySecond || stepFinish || stepOrder) && <ButtonWhite text="BACK" handleClick={() => prev()} />}
      </div>
      <div className="checkout__btnWrapp-next">
        {stepKeySamplwSubmition && !dataSampleFirst && (
          <div className="checkout__btnWrapp-next finish">
            <p>
              <b>You did not provide required information for all samples.</b>
            </p>

            <p>Please provide all required information in order to continue.</p>
          </div>
        )}
        {stepPrimers && dataPrimers &&(
          <div className="checkout__btnWrapp-next finish">
            <p>
              <b>You did not provide required information for all samples.</b>
            </p>

            <p>Please provide all required information in order to continue.</p>
          </div>
        )}
        {stepFinish && (
          <div className="checkout__btnWrapp-next finish">
            <p>
              Click <b>FINISH </b>and on the next page we will summarize your
              order.
            </p>

            <p>
              <b>Then you will be able to SUBMIT.</b>
            </p>
          </div>
        )}
        <div className="checkout__btnWrapp-next btn">
          <ButtonGradient
            text={buttonText}
            handleClick={handleSubmit}
            disabled={disabled}
          />
        </div>
        {/* {current === 4 && (
          <div className="checkout__btnWrapp-next btn">
            <ButtonGradient
              text="PRINT"
              icon={<Printer className="share-icon" size={20} />}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default CheckoutButtons;
