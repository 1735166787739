import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { ProfileProvider } from './components/context/profileInfoContext';
import Modal from 'react-modal';
import { ModalsUserProvider } from './components/context/modalProfileContext/modalsProfileContext';
import ModalChangeUser from './components/modals/changeUserData/ModalChangeUser';
import { UserAccountProvider } from './components/context/userAccount/userAccountContext';
import { SubmissionProvider } from './components/context/submissionContext/submissionContext';
import { OrdersProvider } from './components/context/orders/ordersContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const rootElement = document.getElementById('root');

Modal.setAppElement(rootElement);

root.render(
  <React.StrictMode>
    <ProfileProvider>
      <ModalsUserProvider>
        <UserAccountProvider>
          <SubmissionProvider>
            <OrdersProvider>
              <App />
            </OrdersProvider>
          </SubmissionProvider>
        </UserAccountProvider>
        <ModalChangeUser />
      </ModalsUserProvider>
    </ProfileProvider>
  </React.StrictMode>
);
