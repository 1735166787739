import React from 'react';
import { Select } from 'antd';
import './SortingCustom.scss';
const SortingCustom = () => {
  const handleChange = value => {
    // console.log(`selected ${value}`);
  };

  return (
    <div className="sorting">
      Sort by:
      <Select
        allowClear="true"
        placeholder="Select...."
        style={{
          width: 124,
          borderRadius: 32,
        }}
        onChange={handleChange}
        options={[
          {
            value: 'date',
            label: 'Date',
          },
          {
            value: 'month',
            label: 'Month',
          },
        ]}
      />
    </div>
  );
};

export default SortingCustom;
