import React from 'react';

const ItemHeader = ({ isLoading, setIsLoading, props }) => {
  return (
    <>
      <img
        src={props.image}
        alt="img"
        className="service__img"
        style={{ display: isLoading ? 'none' : 'block' }}
      />
      <div className="service__info">
        <h2 className="service__title">{props.name}</h2>
        <p className="service__description">{props.description}</p>
      </div>
    </>
  );
};

export default ItemHeader;
