import React from 'react';
import { useNavigate } from 'react-router-dom';

import thank from '../../../../../../components/assets/images/thanks.png';
import './ThankYou.scss';
import ButtonGradient from '../../../../../../components/ui/button/ButtonGradient';

const ThankYou = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="thankyou">
      <div className="thankyou-container">
        <div className="thankyou-container__icon">
          <img src={thank} alt="thank" />
        </div>
        <h1 className="thankyou-container__title"> Congratulations!</h1>
        <div>
          <p>
            You just submitted this project to
            <b> {data[0]?.university?.name}</b> 
          </p>
          <p>We will be tracking your shipment. </p>
          <p>You will receive a notification once the lab receives your package.</p>
        </div>
        <div className="thankyou-container__btn">
          <ButtonGradient text="OK" handleClick={() => navigate('/orders')} />
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
