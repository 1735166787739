import React, { useState } from 'react';
import DefaultFileImport from '../DefaultFileImport/DefaultFileImport';
import ButtonWhite from '../../../../../../components/ui/button/buttonWhite/ButtonWhite';
import { ChevronsDownUp } from 'lucide-react';

function DefaultIputTypeCard({
  templateFile,
  setTemplateFile,
  filesUploaded,
  setFilesUploaded,
  serviceType,
  isTableExpanded,
  setIsTableExpanded,
}) {
  return (
    <div >
      <h2 className="firstStep__title">Sample Information</h2>

      <div className="firstStep__scalling">
        <p className="firstStep__text">
          Please download the templates and fill them in. After that upload the
          files, and proceed to the next step.
        </p>
        <div className="firstStep__scalBtn">
          <ButtonWhite
            text={isTableExpanded ? 'EXPAND ' : 'BACK TO DEFAULT VIEW'}
            icon={<ChevronsDownUp className="quote-icon" size={20} />}
            handleClick={() => setIsTableExpanded(!isTableExpanded)}
          />
        </div> 
      </div>
      <DefaultFileImport
        filesUploaded={filesUploaded}
        setFilesUploaded={setFilesUploaded}
        templateFile={templateFile}
        setTemplateFile={setTemplateFile}
        serviceType={serviceType}
      />
    </div>
  );
}

export default DefaultIputTypeCard;
