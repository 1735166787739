import React from 'react';
import './ButtonGradient.scss';
const ButtonGradient = ({ text, icon, handleClick, disabled }) => {
  const buttonClasses = disabled ? 'btn-gradient disabled' : 'btn-gradient';

  return (
    <button className={buttonClasses} onClick={handleClick} disabled={disabled}>
      {icon}
      {text}
    </button>
  );
};

export default ButtonGradient;
