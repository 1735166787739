import React from 'react';

import { Col, Row } from 'antd';
import Logo from '../layout/logo/Logo';
import NavBarItem from '../layout/navbar/NavBarItem';
import { MessagesSquare, User } from 'lucide-react';
import { RiFileTextLine, RiTeamLine, RiTestTubeLine } from 'react-icons/ri';
import LoginInfo from '../userRole/loginInfo/LoginInfo';
import '../layout/navbar/NavBar.scss';

const Header = ({ isLoading, tokenIsValid, onLogout }) => {
  return (
    <Row className="navbar__container">
      <>
        <Col className="navbar__container__wrapper__sidebar">
          <div>
            <Logo />
            <ul>
              <div className="navbar__container_divider" />
              <NavBarItem
                icon={<User className="quote-icon" size={24} />}
                label="Account"
                to={'/account'}
              />
              <div className="navbar__container_divider" />
              <NavBarItem
                icon={<RiTestTubeLine className="quote-icon" size={24} />}
                label="Orders"
                to={'/orders'}
                isOrder={true}
              />
              <div className="navbar__container_divider" />
              <NavBarItem
                icon={<MessagesSquare className="quote-icon" size={24} />}
                label="Messenger"
                to={'/messenger'}
              />
              <div className="navbar__container_divider" />
              <NavBarItem icon={<RiTeamLine id="quote-icon" />} label="Team" to={'/team'} />
              <div className="navbar__container_divider" />
              <NavBarItem icon={<RiFileTextLine id="quote-icon" />} label="Quotes" to={'/quotes'} />
            </ul>
          </div>
          <LoginInfo isLoading={isLoading} tokenIsValid={tokenIsValid} onLogout={onLogout} />
        </Col>
      </>
    </Row>
  );
};

export default Header;
