import { Col, Row } from 'antd';
import React from 'react';
import './HeadingNavbar.scss';

const HeadingNavbarSettings = ({ title }) => {
  return (
    <Col xs={24} className="headingStyle">
      <Row className="headingStyle__row">
        <Col className="headingStyle__row__left">
          <h1>{title}</h1>
        </Col>
      </Row>
    </Col>
  );
};

export default HeadingNavbarSettings;
