import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';

import './TableEnviront.scss';
import EnvironmetSettings from './EnvironmetSettings';
import { v4 as uuidv4 } from 'uuid';

const TableEnvironmental = ({ handleAddRow, handleDeleteRow, envirTable, setEnvirTable }) => {
  const [visibleRows, setVisibleRows] = useState({});

  const toggleVisibleRow = index => {
    setVisibleRows({
      ...visibleRows,
      [index]: !visibleRows[index],
    });
  };
  const isRowCompleted = row => {
    return Object.values(row).every(val => {
      if (typeof val === 'string') {
        return val.trim() !== '';
      }

      return true;
    });
  };

  const generateUniqueKey = index => {
    return `${index}-${uuidv4()}`;
  };

  return (
    <div>
      <div className="env-table">
        <table className="env-table__table">
          <tbody>
            <tr>
              <th className="env-table__th">#</th>
              <th className="env-table__th">
                <div className="env-table__header">
                  <div className="env-table__header-sampleName">Sample Name</div>
                  <div className="env-table__header-primerName">Tube Barcode</div>
                  <div className="env-table__header-molarity">Sample Type</div>
                  <div className="env-table__header-instructions">Volume</div>
                  <div className="env-table__header-instructions">Volume Unit</div>

                  <div className="env-table__header-organism">Organism</div>
                </div>
              </th>
            </tr>
            {envirTable.map((row, index) => {
              const uniqueKey = generateUniqueKey(index);

              return (
                <tr
                  key={uniqueKey}
                  // className={isRowCompleted(row) ? "row-complete" : ""}
                >
                  <td className="env-table__id">{index + 1}</td>
                  <td>
                    <div className="env-table__inputsInfo">
                      <div
                        className={isRowCompleted(row) ? 'row-complete' : 'env-table__inputsRow'}
                      >
                        <div
                          onClick={() => toggleVisibleRow(index)}
                          className="env-table__icon"
                        ></div>

                        <EnvironmetSettings
                          row={row}
                          setEnvirTable={setEnvirTable}
                          index={index}
                          envirTable={envirTable}
                          isRowCompleted={isRowCompleted}
                        />

                        <Trash2
                          className="delete-icon"
                          onClick={() => handleDeleteRow(row.id)}
                          color="#002060"
                          width={18}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div onClick={handleAddRow} className="env-table__addRow">
        + SAMPLES
      </div>
    </div>
  );
};

export default TableEnvironmental;
