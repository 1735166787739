import React from 'react';
import './RadioSelection.scss';

const RadioSelection = ({ name, value, checked, onChange, label, text, id }) => {
  return (
    <div className="radio-selection">
      <input type="radio" id={id} name={name} value={value} checked={checked} onChange={onChange} />
      <label htmlFor={id} onClick={onChange}>
        {label}
      </label>
    </div>
  );
};

export default RadioSelection;
