import React, { useContext } from 'react';
import './PersonalInfo.scss';
import { Col, Row, Input, Spin } from 'antd';
import ButtonWhite from '../../../../../components/ui/button/buttonWhite/ButtonWhite';
import { Info, Mail, MessageSquare } from 'lucide-react';
import { modalsProfileContext } from '../../../../../components/context/modalProfileContext/modalsProfileContext';
import ButtonGradient from '../../../../../components/ui/button/ButtonGradient';
import userAccountContext from '../../../../../components/context/userAccount/userAccountContext';

const PersonalInfo = ({ data, editMode, handleCancelEdit, handleSaveClick, loading }) => {
  const { setChangeUserInfo, setChangeEmail, setChangePass, setRemoveUserAcc } =
    useContext(modalsProfileContext);
  const { firstName, setFirstName, lastName, setLastName, phoneNumber, setPhoneNumber } =
    useContext(userAccountContext);

  const handleOpenEmailModal = () => {
    setChangeEmail(true);
    setChangePass(false);
    setRemoveUserAcc(false);
    setChangeUserInfo(true);
  };
  const handleOpenPassModal = () => {
    setChangePass(true);
    setChangeEmail(false);
    setRemoveUserAcc(false);
    setChangeUserInfo(true);
  };

  return (
    <div className="personal">
      <div className="personal__section">
        {loading ? (
          <Spin size="default" />
        ) : (
          <>
            <Row justify="space-between">
              <Col span={11}>
                <div>
                  <p className="personal-title">First Name</p>
                  {editMode && (
                    <Input
                      type="text"
                      defaultValue={data?.firstName}
                      value={firstName}
                      onChange={({ target }) => setFirstName(target.value)}
                    />
                  )}
                  {!editMode && <p className="personal-text">{data?.firstName}</p>}
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <p className="personal-title">Last Name</p>
                  {editMode && (
                    <Input
                      type="text"
                      defaultValue={data?.lastName}
                      value={lastName}
                      onChange={({ target }) => setLastName(target.value)}
                    />
                  )}
                  {!editMode && <p className="personal-text">{data?.lastName}</p>}
                </div>
              </Col>
            </Row>
            {!editMode && (
              <Row justify="space-between" align="middle">
                <Col span={11}>
                  <div>
                    <p className="personal-title">Email</p>
                    <p className="personal-text">{data?.email}</p>
                  </div>
                </Col>
                <Col span={12}>
                  <ButtonWhite
                    text="CHANGE EMAIL"
                    icon={<Mail className="quote-icon" size={20} />}
                    handleClick={handleOpenEmailModal}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <div>
                  <p className="personal-title">Contact Phone</p>

                  {editMode && (
                    <Input
                      type="tel"
                      placeholder="e.g., +1234567890"
                      value={phoneNumber}
                      onChange={({ target }) => setPhoneNumber(target.value)}
                    />
                  )}
                  {!editMode && <p className="personal-text">{data?.phoneNumber}</p>}
                </div>
              </Col>
            </Row>
            {!editMode && (
              <Row justify="space-between" align="middle">
                <Col span={10}>
                  <ButtonWhite
                    text="CHANGE PASSWORD"
                    icon={<MessageSquare className="quote-icon" size={20} />}
                    handleClick={handleOpenPassModal}
                  />
                </Col>
                <Col span={12}>
                  {/* <div className="personal-lastUpdate">
                    <Info className="info-icon" size={24} color="#36C6E6" />
                    <p>

                    </p>
                  </div> */}
                </Col>
              </Row>
            )}
            {editMode && (
              <Row justify="space-between">
                <Col span={10}>
                  <ButtonWhite
                    text="CANCEL"
                    className="user-cartBox__cancelButton"
                    handleClick={handleCancelEdit}
                  />
                </Col>

                <Col span={12}>
                  <ButtonGradient text="SAVE" handleClick={handleSaveClick} />
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PersonalInfo;
