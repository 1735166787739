import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import { BACKEND_URL } from '../../../../../services/serverData';
import './FinishStep.scss';
import RadioSelection from '../ThirdStep/radioSelection/RadioSelection';
import ShippingInfo from './shippingInfo/ShippingInfo';
import CheckoutButtons from '../../buttons/CheckoutButtons';
import ReactGA from "react-ga4"


const FinishStep = ({ current, next, prev, stepKey }) => {
  const [indexType, setIndexType] = useState('labelShipping');
  const { orderId: id, submitId: subId } = useParams();

  ReactGA.send({
    hitType: "pageview",
    page: `/submission/${id}/${subId}`,
    title: "Delivery Options Page"
  })
  const orderId = id;

  const token = localStorage.getItem('gravl_token');
  const [isLoading, setIsLoading] = useState(true);
  const [deliveryData, setDeliveryData] = useState(null);

  const [selectedDeliveryOption, setSelectedDeliveryOption] =
    useState('labelShipping');

  const handleIndexChange = (e) => {
    const { value } = e.target;

    setIndexType(value);
    setSelectedDeliveryOption(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/admin/services/${orderId}/configuration/delivery-options`,
        );
        // console.log(response.data)
        setDeliveryData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orderId]);

  const handleSubmitFinish = async (e) => {
    e.preventDefault();

    let optionToSend = selectedDeliveryOption || null;

    if (selectedDeliveryOption === 'SkipShipping') {
      optionToSend = null;
    }
    try {
      const response = await axios.put(
        `${BACKEND_URL}/admin/orders/${orderId}/DeliveryOptions`,
        { selectedDeliveryOption: optionToSend },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            orderId: orderId,
          },
        },
      );
      message.success('Delivery Options updated successfully!');
      next();
      // console.log(response);
    } catch (error) {
      console.error('Error:', error);
      message.error(
        error.response?.data?.message ||
          'Failed to update Delivery Options. Please try again.',
      );
    }
  };

  return (
    <>
      <div className="finish-step">
        <div className="finish-step__inputs">
          <h2 className="finish-step__title">Shipping Instructions</h2>
          {/* <ul className='finish-step__list'>
            <li className="finish-step__list">Samples should be shipped <b>on dry ice</b>, sufficient for shipment to the appropriate Analysis Service lab.</li>
            <li className="finish-step__list">Make sure the plates are securely sealed, add extra adsorption paper in case of leakage and make sure to place the samples in a sealed bag.</li>
            <li className="finish-step__list">Ensure that the heavy dry ice will not damage the samples during shipment.</li>
            <li className="finish-step__list">Contact your preferred courier for local regulations regarding appropriate labelling for risk assessment and shipment of your human samples on dry ice.</li>
            <li className="finish-step__list">Place a document referencing the Quote number identifying the samples and the project they belong to (for example a copy of Attachment A).</li>
            <li className="finish-step__list">Send the tracking number and courier company name, and add quote number in the communication.</li>
            <li className="finish-step__list">Note: Our business hours are Mon-Fri 8 a.m. to 5 p.m.</li>
            <li className="finish-step__list">Upon arrival, the samples and documents will be examined, and you will receive a confirmation email. Samples will be stored at -80°C.</li>
          </ul> */}
          <ul className="finish-step__list">
            {deliveryData?.shippingInstructions.map((li) => {
              return (
                <li className="finish-step__list">
                  {li.parts.map((part) => {
                    if (part.bold) {
                      return <b>{part.text}</b>;
                    } else if (part.cursive) {
                      return <i>{part.text}</i>;
                    } else {
                      return part.text;
                    }
                  })}
                </li>
              );
            })}
          </ul>
        </div>
        <ShippingInfo deliveryData={deliveryData} />
      </div>
      <CheckoutButtons
        current={current}
        next={next}
        prev={prev}
        handleSubmit={handleSubmitFinish}
        buttonText={'FINISH'}
        stepFinish={stepKey}
      />
    </>
  );
};

export default FinishStep;
