import { ChevronDown, ChevronRight, Info, SendHorizonal } from 'lucide-react';
import React from 'react';

import ServiceList from '../serviceList/ServiceList';
import './SampleDetails.scss';
import ButtonWhite from '../../ui/button/buttonWhite/ButtonWhite';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import ReactGA from "react-ga4"


const SampleDetails = ({ visibleSample, handleVisibleSample, orders }) => {
  visibleSample && ReactGA.event({
    category: "Tab Open",
    action: "Sample Information",
    label: "Orders Page",
  });

  const zeroSampleItems = orders?.orderItems
    ? orders.orderItems.filter(item => item.samplesAmount === 0)
    : [];

  const nonZeroSampleDetails = orders?.orderItems
    .filter(item => item.samplesRequired === true)
    .filter(item => item.samplesAmount > 0)
    .map(item => ({
      id: item.id,
      samplesAmount: item.samplesAmount,
    }));

  const notRequiredItems = orders?.orderItems
    ? orders.orderItems.filter(item => item.samplesRequired === false)
    : [];

  return (
    <div className="sample-details">
      <div className="sample-details__main" onClick={handleVisibleSample}>
        <div className="sample-details__main link">
          <div className="sample-details__main link-pointer">
            {visibleSample ? <ChevronDown color="#36C6E6" /> : <ChevronRight color="#36C6E6" />}
            <p className="sample-details__main link-text">
              Sample Information&nbsp;

              {nonZeroSampleDetails.length + notRequiredItems.length}
              /
              {orders?.orderItems.length}
              {nonZeroSampleDetails.length + notRequiredItems.length !== orders?.orderItems.length ? (
                <Info color="#E83434" size={24} />
              ) : (
                <IoMdCheckmarkCircleOutline className="check-icon" />
              )}
            </p>
          </div>
        </div>
      </div>
      {visibleSample && (
        <div>
          {orders?.orderItems.map((item, id) => (
            <ServiceList item={item} key={id} ordersId={orders.id} />
          ))}
          <div className="sample-details__btns">
          {orders?.status !== 'Completed' && (
            <div className="sample-details__btns-text">
              <p>
                <b>You did not provide required information for all services.</b>
              </p>
              <p>Please provide all required information in order to continue.</p>
            </div>)}
            <div className="sample-details__btns-submit">
              <ButtonWhite
                text="CONFIRM AND SEND SAMPLE INFORMATION"
                icon={<SendHorizonal size={24} className="quote-icon" />}
                // onClick={handleSendInfoSample}
                disabled={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SampleDetails;
