import React from 'react';
import './OrderSummaryTable.scss';
import { formatPrice } from '../../../../../../components/utils/currencyUtils';

function OrderSummaryTable({ data, currency }) {
  return (
    <div className="order-summary-table">
      <div className="order-summary-table__header">
        <p className="order-summary-table__text">Name of service</p>
        <p className="order-summary-table__text">Number of units</p>
        <p className="order-summary-table__text">Order total</p>
      </div>
      <div className="order-summary-table__body">
        {data.map((item, index) => {
          return (
            <div className="order-summary-table__row">
              <p className="order-summary-table__row-text">{item.name}</p>
              <p className="order-summary-table__row-text">{item.amount}</p>
              <p className="order-summary-table__row-text">
                {formatPrice(item.price, currency)}
              </p>
            </div>
          );
        })}
      </div>
      <div className="order-summary-table__total">
        Total:{' '}
        {formatPrice(
          data.reduce((acc, item) => acc + item.price, 0),
          currency,
        )}
      </div>
    </div>
  );
}

export default OrderSummaryTable;
