import React, { useState } from 'react';
import OrderDeatils from '../orderDetails/OrderDeatils';
import './SampleInfo.scss';
import TeamInfo from '../teamInfo/TeamInfo';
import SampleDetails from '../sampleDetails/SampleDetails';
import SortingCustom from '../../ui/sorting/SortingCustom';

const SampleInfo = ({ orders }) => {
  const [visibleOrder, setVisibleOrder] = useState({});
  const [visibleTeam, setVisibleTeam] = useState({});
  const [visibleSample, setVisibleSample] = useState({});

  const toggleVisibleOrder = index => {
    setVisibleOrder(prev => ({
      ...prev,
      [index]: !prev[index], // Toggle specific order detail
    }));
  };

  const toggleVisibleTeam = index => {
    setVisibleTeam(prev => ({
      ...prev,
      [index]: !prev[index], // Toggle specific team detail
    }));
  };

  const handleVisibleSample = index => {
    setVisibleSample(prev => ({
      ...prev,
      [index]: !prev[index], // Toggle the specific sample
    }));
  };
  const sortedOrders = orders.slice().sort((a, b) => b.id - a.id);
  return (
    <>
      <SortingCustom />
      {sortedOrders.length > 0 ? (
        sortedOrders.map((item, index) => (
          <div className="sample-info" key={item.id || index}>
            <OrderDeatils
              visibleOrder={visibleOrder[index]}
              handleVisibleChange={() => toggleVisibleOrder(index)} // Corrected
              orders={item}
            />
            <TeamInfo
              visibleTeam={visibleTeam[index]}
              handleVisibleTeam={() => toggleVisibleTeam(index)} // Corrected
              orders={item}
            />
            <SampleDetails
              visibleSample={visibleSample[index]}
              handleVisibleSample={() => handleVisibleSample(index)} // Corrected
              orders={item}
            />
          </div>
        ))
      ) : (
        <p>No orders to display.</p>
      )}
    </>
  );
};

export default SampleInfo;
