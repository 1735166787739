import { useEffect, useState } from 'react';
import './QuotesPage.scss';
import { Card, Divider } from 'antd';
import axios from 'axios';
import { BACKEND_URL } from '../../../services/serverData';
import ReactGA from "react-ga4"

const QuoteCard = ({ quote }) => {
  const getQuote = async () => {
    const token = localStorage.getItem('gravl_token');

    try {
      const response = await axios.get(`${BACKEND_URL}/admin/quote/${quote.name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
        },
        responseType: 'blob',
        params: {
          format: 'pdf',
        },
      });

      //download pdf from response
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'quote.pdf';

      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <Card
      key={quote}
      title={'Quote ' + quote.name}
      bordered={true}
      style={{ width: 300 }}
      styles={{
        extra: {
          color: '#007bff',
          fontWeight: '500',
          cursor: 'pointer',
        },
      }}
      extra={<div onClick={getQuote}>Download</div>}
    >
      <p>
        Created at:{' '}
        {new Date(quote.dateCreated).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
      </p>
    </Card>
  );
};

const LoadingCards = () => {
  return (
    <>
      <Card key={1} loading={true} bordered={true} style={{ width: 300 }} />
      <Card key={2} loading={true} bordered={true} style={{ width: 300 }} />
      <Card key={3} loading={true} bordered={true} style={{ width: 300 }} />
      <Card key={4} loading={true} bordered={true} style={{ width: 300 }} />
    </>
  );
};

function QuotesPage() {
  ReactGA.send({
    hitType: "pageview",
    page: `/quotes`,
    title: "Quotes Page"
  })
  
  const [loading, setLoading] = useState(false);
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('gravl_token');

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BACKEND_URL}/admin/account/quotes`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        setQuotes(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Error:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="quotes">
      <h2 className="quotes__title">Quotes</h2>
      <Divider style={{ marginBottom: '12px' }} />
      <div className="quotes__cards-section">
        <div className="quotes__cards-title-container">
          <h5 className="quotes__cards-title">Quotes List</h5>
        </div>
        <div className="quotes__cards-container">
          {loading ? (
            <LoadingCards />
          ) : (
            <>
              {quotes.map((quote, index) => (
                <QuoteCard key={index} quote={quote} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuotesPage;
