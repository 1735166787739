import React from 'react';
import './ButtonConfirm.scss';

const ButtonConfirm = ({ text, icon, handleClick }) => {
  return (
    <button className="btn-confirm" onClick={handleClick}>
      {icon}
      {text}
    </button>
  );
};

export default ButtonConfirm;
