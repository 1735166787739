import { createContext, useState } from 'react';

export const ordersContext = createContext();

export function OrdersProvider({ children }) {
  const [orderItems, setOrdersItems] = useState([]);
  const [selectedOrderConfiguration, setSelectedOrderConfiguration] = useState({});

  return (
    <ordersContext.Provider
      value={{
        orderItems,
        setOrdersItems,
        selectedOrderConfiguration,
        setSelectedOrderConfiguration,
      }}
    >
      {children}
    </ordersContext.Provider>
  );
}

export default ordersContext;
