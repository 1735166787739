import React, { useEffect, useState } from 'react';
import { Switch, message } from 'antd';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BACKEND_URL } from '../../../../../services/serverData';
import './ThirdStepPrimers.scss';
import AddPrimerTable from './addTable/AddPrimerTable';
import IndicesSection from './indices/IndicesSection';

import CheckoutButtons from '../../buttons/CheckoutButtons';

import FileImport from '../FirstQaStep/importFile/FileImport';
import * as XLSX from 'xlsx';
import ReactGA from "react-ga4"


const ThirdStepPrimers = ({ current, next, prev, stepKey }) => {
  const { orderId: id, submitId: subId } = useParams();

  ReactGA.send({
    hitType: "pageview",
    page: `/submission/${id}/${subId}`,
    title: "Sequencing Primers and Indices Page"
  })

  const orderId = id;
  const token = localStorage.getItem('gravl_token');

  const [selectedOption, setSelectedOption] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [primerData, setPrimerData] = useState(null);
  const [primers, setRowsPrimers] = useState([]);
  const [indices, setAddIndices] = useState([]);
  const [indexType, setIndexType] = useState('singleIndex');
  const [selectedIndices, setSelectedIndices] = useState(true);
  const [addedPrimers,setAddedPrimers] = useState(false)

  const handleSwitchChange = isChecked => {
    setSelectedOption(isChecked);
  };

  // get available input
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/admin/services/${orderId}/configuration/primers-indices`
        );

        setPrimerData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [orderId]);
  // console.log(primerData);
  useEffect(() => {
    // Initialize the state with two default rows when the component mounts
    const initialRows = [
      {
        id: 1,
        sampleName: '',
        primerName: '',
        molarity: '',
        volume: '',
        instructions: '',
      },
    ];

    setRowsPrimers(initialRows);
  }, []);

  useEffect(() => {
    const initialRowsIndices = [{ id: 1, name: '', size: '' }];
    setAddIndices(initialRowsIndices);
  }, []);

  const handleAddRow = () => {
    const lastId = primers.length > 0 ? primers[primers.length - 1].id : 0;
    const newRow = {
      id: lastId + 1,
      sampleName: '',
      primerName: '',
      molarity: '',
      volume: '',
      instructions: '',
    };

    setRowsPrimers([...primers, newRow]);
  };

  const handleDeleteRow = id => {
    const updatedRows = primers.filter(row => row.id !== id);

    setRowsPrimers(updatedRows);
  };

  const handleSwitchIndices = isChecked => {
    setSelectedIndices(isChecked);
  };
  const handleIndexChangeIndices = e => {
    setIndexType(e.target.value);
  };

  const handleAddRowIndices = () => {
    const newRowIndices = {
      id: indices.length + 1,
      name: '',
      size: '',
    };
    setAddIndices([...indices, newRowIndices]);
  };

  const handleDeleteRowIndices = id => {
    const updatedRows = indices.filter(row => row.id !== id);
    setAddIndices(updatedRows);
  };

  const handleSubmitPrimers = async e => {
    e.preventDefault();
setAddedPrimers(true)
    try {
      const primersData = selectedOption ? primers : null;
      const indicesData = selectedIndices ? indices : null;
    
      const response = await axios.put(
        `${BACKEND_URL}/admin/orders/${orderId}/PrimersIndices`,
        { primers: primersData, indices: indicesData },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            orderId: orderId,
          },
        }
      );
      message.success('Sequencing Primers and Indices updated successfully!');
      next();
      // console.log('Response:', response);
    } catch (error) {
      console.error('Error:', error);
      message.error(
        error.response?.data?.message ||
          'Failed to update Sequencing Primers and Indices. Please try again.'
      );
    }
  };

  const generateExcelTemplate = () => {
    let headers;

    const backendToDisplayMap = {
      sampleName: 'Sample Name',
      primerName: 'Primer Name',
      molarity: 'Molarity',
      volume: 'Volume',
      instructions: 'Instructions',
    };

    headers = primers;

    if (!headers || !headers[0]) {
      console.error('Headers are undefined or empty');

      return;
    }
    headers = Object?.keys(headers[0]);

    if (headers[0] === 'id') {
      headers = headers.slice(1);
    }
    const displayHeaders = headers.map(header => backendToDisplayMap[header] || header);
    const data = [displayHeaders];

    for (let i = 0; i < 3; i++) {
      // Creating 5 rows
      const row = [];

      data.push(row);
    }

    // console.log(data);

    const worksheet = XLSX.utils.aoa_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');

    XLSX.writeFile(workbook, 'template.xlsx');
  };

  const backendFieldMap = {
    'Sample Name': 'sampleName',
    'Primer Name': 'primerName',
    Molarity: 'molarity',
    Volume: 'volume',
    Instructions: 'instructions',
  };
  const handleFileUpload = event => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false });

      const headers = excelData.shift();

      const backendHeaders = headers.map(header => backendFieldMap[header] || header);

      let headersToCheck;
      let sampleType;
      //check if header is correct
      headersToCheck = primers;
      sampleType = 'Library';

      if (headersToCheck) {
        headersToCheck = Object.keys(headersToCheck[0]);

        if (headersToCheck[0] === 'id') {
          headersToCheck = headersToCheck.slice(1);
        }
      } else {
        console.error('Headers to check are invalid.');

        return;
      }
      const headersMatch = backendHeaders.every((header, index) => {
        return header === headersToCheck[index];
      });
      headers.every((header, index) => {
        if (header !== headersToCheck[index]) {
          return false;
        } else {
          return true;
        }
      });
      if (!headersMatch) {
        console.error('Headers do not match the expected format');

        return;
      }

      const validRows = excelData.filter(row => row.some(cell => cell !== undefined));

      for (let row = 0; row < excelData.length; row++) {
        if (sampleType === 'Library') {
          setRowsPrimers(prev => {
            const newRows = validRows
              .map((row, idx) => {
                const parsedRow = row
                  .map((cell, index) => [backendHeaders[index], cell])
                  .filter(value => Boolean(value));

                if (parsedRow.every(value => value)) {
                  return {
                    id: prev.length + 1 + idx,
                    ...Object?.fromEntries(parsedRow),
                  };
                } else {
                  return [];
                }
              })
              .flat();

            return [...newRows];
          });
        } else if (sampleType === 'DNAorRNA') {
          // console.log('DNAorRNA');
        } else if (sampleType === 'EnvironmentalSample') {
          // console.log('EnvironmentalSample');
        }
      }

      // Pass the parsed data to the parent component
      // onDataImport(excelData);
    };

    reader.readAsArrayBuffer(file);
  };
  const areAllFieldsEmpty = (obj) => {
    return Object.values(obj).every(value => value !== "");
  };

  const isDataPrimersEmpty = primers.every(areAllFieldsEmpty);
  return (
    <>
      <div className="thirdStep">
        <div className="thirdStep__inputs">
          <h2 className="thirdStep__titles">Sequencing Primers</h2>
          <div className="thirdStep__wrapp">
            <div className="thirdStep__wrapp switcher">
              <p className="thirdStep__text">
                Do your samples require Custom Sequencing Primer(s)?
              </p>
              <div className="thirdStep__option">
                <div
                  className={selectedOption ? 'no' : 'yes'}
                  onClick={() => setSelectedOption(false)}
                >
                  No
                </div>
                <Switch
                  defaultChecked={selectedOption}
                  onChange={handleSwitchChange}
                  className="thirdStep__switch"
                />
                <div
                  className={selectedOption ? 'yes' : 'no'}
                  onClick={() => setSelectedOption(true)}
                >
                  Yes
                </div>
              </div>
            </div>
          </div>
          {primerData?.primers === true ? (
            <AddPrimerTable
              rows={primers}
              setRows={setRowsPrimers}
              handleAddRow={handleAddRow}
              handleDeleteRow={handleDeleteRow}
            />
          ) : null}
          <FileImport
            generateExcelTemplate={generateExcelTemplate}
            handleFileUpload={handleFileUpload}
          />
        </div>
        <IndicesSection
          primerData={primerData}
          selectedOption={selectedIndices}
          handleSwitchChange={handleSwitchIndices}
          setSelectedOption={setSelectedIndices}
          indexType={indexType}
          setIndexType={setIndexType}
          handleAddRow={handleAddRowIndices}
          handleDeleteRow={handleDeleteRowIndices}
          handleIndexChange={handleIndexChangeIndices}
          addLibrary={indices}
          setAddLibrary={setAddIndices}
        />
      </div>
      <CheckoutButtons
        current={current}
        next={next}
        prev={prev}
        isLoading={isLoading}
        handleSubmit={handleSubmitPrimers}
        buttonText={'CONTINUE'}
        stepPrimers={stepKey}
        dataPrimers={!isDataPrimersEmpty}
      />
    </>
  );
};

export default ThirdStepPrimers;
