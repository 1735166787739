import React from 'react';
import { Trash2 } from 'lucide-react';
import './AddPrimerTable.scss';
import { v4 as uuidv4 } from 'uuid';

const AddPrimerTable = ({ rows, setRows, handleAddRow, handleDeleteRow }) => {
  const generateUniqueKey = index => {
    return `${index}-${uuidv4()}`;
  };

  return (
    <div className="add-table">
      <table className="add-table__table">
        <tbody>
          <tr>
            <th className="add-table__th">#</th>
            <th className="add-table__th">
              <div className="add-table__header">
                <div className="add-table__header-sampleName">Sample Name</div>
                <div className="add-table__header-primerName">Primer Name</div>
                <div className="add-table__header-molarity">Molarity (nM)</div>
                <div className="add-table__header-volume">Volume (uL)</div>
                <div className="add-table__header-instructions">Instructions</div>
              </div>
            </th>
          </tr>
          {rows.map((row, index) => {
            const uniqueKey = generateUniqueKey(index);

            return (
              <tr key={index}>
                <td className="add-table__id">{index + 1}</td>
                <td>
                  <div className="add-table__inputsInfo">
                    <input
                      placeholder="Sample..."
                      type="text"
                      value={row.sampleName}
                      onChange={e => {
                        const updatedRows = [...rows];
                        updatedRows[index].sampleName = e.target.value;
                        setRows(updatedRows);
                      }}
                    />
                    <input
                      placeholder="Primer..."
                      type="text"
                      value={row.primerName}
                      onChange={e => {
                        const updatedRows = [...rows];
                        updatedRows[index].primerName = e.target.value;
                        setRows(updatedRows);
                      }}
                    />
                    <input
                      placeholder="Molarity"
                      type="text"
                      value={row.molarity}
                      onChange={e => {
                        const updatedRows = [...rows];
                        updatedRows[index].molarity = e.target.value;
                        setRows(updatedRows);
                      }}
                    />
                    <input
                      placeholder="Volume"
                      type="text"
                      value={row.volume}
                      onChange={e => {
                        const updatedRows = [...rows];
                        updatedRows[index].volume = e.target.value;
                        setRows(updatedRows);
                      }}
                    />
                    <input
                      placeholder="Instructions"
                      type="text"
                      value={row.instructions}
                      onChange={e => {
                        const updatedRows = [...rows];
                        updatedRows[index].instructions = e.target.value;
                        setRows(updatedRows);
                      }}
                    />
                    <Trash2
                      className="delete-icon"
                      onClick={() => handleDeleteRow(row.id)}
                      color="#002060"
                      width={18}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div onClick={handleAddRow} className="add-table__addRow">
        + Add Primer
      </div>
    </div>
  );
};

export default AddPrimerTable;
