import React from 'react';
import PaymentCard from './card/PaymentCard';

const PaymentUser = ({
  payVisible,
  data,
  handleEditPersonalInfo,
  editPayment,
  handleCancelEdit,
  handleSaveClick,
}) => {


  return (
    <>
      {payVisible && (
        <div className="personal">
          {data?.map(item => (
            <PaymentCard
              item={item}
              key={item.id}
              handleEditPersonalInfo={handleEditPersonalInfo}
              editPayment={editPayment}
              handleSaveClick={handleSaveClick}
              handleCancelEdit={handleCancelEdit}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default PaymentUser;
