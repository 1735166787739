import React, { useState } from 'react';
import CobiomicFileImport from '../CobiomicFileImport/CobiomicFileImport';
import ButtonWhite from '../../../../../../components/ui/button/buttonWhite/ButtonWhite';
import { ChevronsDownUp } from 'lucide-react';

function CobiomicCard({ templateFile, setTemplateFile, manifestFile, setManifestFile, filesUploaded, setFilesUploaded, isTableExpanded,
  setIsTableExpanded, }) {


  return (
    <>
      <h2 className="firstStep__title">Sample Information</h2>

      <div className="firstStep__scalling">
        <p className="firstStep__text">
          Please download the templates and fill them in. After that upload the
          files, and proceed to the next step.
        </p>
        <div className="firstStep__scalBtn">
          <ButtonWhite
            text={isTableExpanded ? 'EXPAND ' : 'BACK TO DEFAULT VIEW'}
            icon={<ChevronsDownUp className="quote-icon" size={20} />}
            handleClick={() => setIsTableExpanded(!isTableExpanded)}
          />
        </div> 
      </div>
      <CobiomicFileImport
        filesUploaded={filesUploaded}
        setFilesUploaded={setFilesUploaded}
        templateFile={templateFile}
        setTemplateFile={setTemplateFile}
        manifestFile={manifestFile}
        setManifestFile={setManifestFile}
      />
    </>
  );
}

export default CobiomicCard;
