import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { BACKEND_URL } from '../../../services/serverData';
import SampleInfo from '../../../components/userRole/sampleInfo/SampleInfo';
import { Tabs } from 'antd';
import HeadingNavbarSettings from '../../../components/layout/heading/HeadingNavbarSettings';
import SearchInput from '../../../components/ui/search/SearchInput';

import './Orders.scss';
import ordersContext from '../../../components/context/orders/ordersContext';
import ReactGA from "react-ga4"

const Orders = () => {
  ReactGA.send({
    hitType: "pageview",
    page: `/orders`,
    title: "Orders Page"
  })

  const { orderItems, setOrdersItems } = useContext(ordersContext);
  const [selectedTab, setSelectedTab] = useState('1');
  const [loading, setLoading] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('gravl_token');
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BACKEND_URL}/admin/account/orders`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        // console.log("Order Data", data);

        setOrdersItems(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Error:', error);
      }
    };
    fetchData();
  }, [setOrdersItems]);

  const handleChange = key => {
    setSelectedTab(key);

    setSelectedComponent();
  };

  const filterOrdersByStatus = status => {
    return orderItems.filter(order => order.status === status);
  };

  const filteredItems = orderItems.filter(item =>
    item.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredBySearch = (items, searchTerm) => {
    return items.filter(item => item.id.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const ordersUserTabs = [
    {
      key: '1',
      label: 'ALL',
      children: <SampleInfo orders={searchTerm ? filteredItems : orderItems} loading={loading} />,
    },
    {
      key: '2',
      label: 'SAMPLE INFO REQUIRED',
      children: (
        <SampleInfo
          orders={
            searchTerm
              ? filteredBySearch(filterOrdersByStatus('Pending'), searchTerm)
              : filterOrdersByStatus('Pending')
          }
          loading={loading}
        />
      ),
    },
    {
      key: '3',
      label: 'IN TRANSIT',
      children: <SampleInfo orders={filterOrdersByStatus('InProgress')} loading={loading} />,
    },
    {
      key: '4',
      label: 'COMPLETED',
      children: <SampleInfo orders={filterOrdersByStatus('Completed')} loading={loading} />,
    },
    {
      key: '5',
      label: 'CANCELLED',
      children: <SampleInfo orders={filterOrdersByStatus('Cancelled')} loading={loading} />,
    },
  ];

  return (
    <div className="orders">
      <div className="orders-wrapper">
        <>
          <HeadingNavbarSettings title="Orders" />
          <SearchInput onSearch={setSearchTerm} />
          <Tabs
            defaultActiveKey="2"
            items={ordersUserTabs}
            onChange={handleChange}
            activeKey={selectedTab}
          />
          {selectedComponent}
        </>
      </div>
    </div>
  );
};

export default Orders;
