import React from 'react';
import './ShippingInfo.scss';

const ShippingInfo = ({deliveryData}) => {
  return (
    <div className="shipping-info">
      <h2 className="shipping-info__title">Shipping Address</h2>
      <p className="shipping-info__description">
        {deliveryData?.address}
      </p>
    </div>
  );
};

export default ShippingInfo;
