import React, { useEffect, useState } from 'react';
import ButtonWhite from '../../../../../../components/ui/button/buttonWhite/ButtonWhite';
import { FileCheck, FolderUp, Info, Upload, X } from 'lucide-react';
import './DefaultFileImport.scss';
import { downloadFile } from '../../../../../../components/utils/downloadUtils';

const DefaultFileImport = ({
  setFilesUploaded,
  templateFile,
  setTemplateFile,
  serviceType,
}) => {
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (templateFile) {
      setFilesUploaded(true);
    } else {
      setFilesUploaded(false);
    }
  }, [templateFile]);

  const handleTemplateChange = (e) => {
    setTemplateFile(e.target.files[0]);
  };

  const handleTemplateRemove = () => {
    setTemplateFile(null);
  };

  const onDownloadTemaplate = () => {
    console.log(serviceType);

    const SamplesCollectionFormName =
      serviceType === 'DataAnalysis'
        ? 'analysisTemplate.xlsx'
        : 'sequencingTemplate.xlsx';

    downloadFile(
      SamplesCollectionFormName,
      'default/' + SamplesCollectionFormName,
    );
  };

  return (
    <div className="firstStep-fileBox">
      {showError ? (
        <p className="import__error">
          Your file has error. Check required entries in inputs.
        </p>
      ) : (
        <>
          <div className="firstStep-fileBox__import">
            {templateFile ? (
              <>
                <div className="cobiomic-import-box">
                  <div className="cobiomic-import-box__icon">
                    <FileCheck
                      className="info-icon"
                      size={20}
                      color="#09C7BE"
                    />
                  </div>
                  <div className="cobiomic-import-box__text-box">
                    <p className="cobiomic-import-box__text">
                      {serviceType === 'DataAnalysis'
                        ? 'Uploaded Sample Analysis'
                        : 'Uploaded sample collection'}
                    </p>
                    <p className="cobiomic-import-box__name">
                      {templateFile?.name.length > 25
                        ? templateFile?.name.substring(0, 25) + '...'
                        : templateFile?.name}
                    </p>
                  </div>
                  <div
                    onClick={handleTemplateRemove}
                    className="cobiomic-import-box__remove"
                  >
                    <X className="info-icon" size={20} color="#E83434" />
                  </div>
                </div>
              </>
            ) : (
              <form className="cobiomic-import">
                <label htmlFor="template-upload" className="btn-white">
                  <Upload className="quote-icon" size={20} />
                  {serviceType === 'DataAnalysis'
                    ? 'Upload Sample Analysis'
                    : 'Upload sample collection'}
                </label>
                <input
                  id="template-upload"
                  type="file"
                  onChange={handleTemplateChange}
                  accept=".xlsx, .xls"
                  className="template-upload"
                />
              </form>
            )}
          </div>
          <div className="firstStep-fileBox__import-template">
            <Info className="info-icon" size={24} />
            <p className="firstStep-fileBox__import-template-text">
              This service supports only template input, so please download our
              <span onClick={onDownloadTemaplate}>
                &nbsp;template blank files.
              </span>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default DefaultFileImport;
