import React from 'react';
import SubmissionItem from './item/SubmissionItem';
import { Minus, Plus } from 'lucide-react';

const DataDeliverables = ({ deliverData }) => {
  const file = deliverData?.rawFiles?.chosen === true;
  const fastDemi = deliverData?.fastqDemultiplexed?.chosen === true;
  const bamMulti = deliverData?.bamMultiplexed?.chosen === true;
  const bamDemulti = deliverData?.bamDemultiplexed?.chosen === true;
  const bamAlign = deliverData?.bamAlignment?.chosen === true;

  return (
    <div className="service-provider">
      <SubmissionItem
        title={
          bamAlign ? (
            <>
              <span>BAM Alignment </span>
              <Plus className="check-icon" size={18} />
            </>
          ) : (
            <>
              <span>BAM Alignment </span>
              <Minus className="check-icon" size={18} />
            </>
          )
        }
        price={deliverData?.bamAlignment?.price}
      />
      <SubmissionItem
        title={
          bamDemulti ? (
            <>
              <span>BAM Demultiplexed </span>
              <Plus className="check-icon" size={18} />
            </>
          ) : (
            <>
              <span>BAM Demultiplexed </span>
              <Minus className="check-icon" size={18} />
            </>
          )
        }
        price={deliverData?.bamDemultiplexed?.price}
      />
      <SubmissionItem
        title={
          bamMulti ? (
            <>
              <span>BAM Multiplexed </span>
              <Plus className="check-icon" size={18} />
            </>
          ) : (
            <>
              <span>BAM Multiplexed </span>
              <Minus className="check-icon" size={18} />
            </>
          )
        }
        price={deliverData?.bamMultiplexed?.price}
      />
      <SubmissionItem
        title={
          fastDemi ? (
            <>
              <span>FASTQ Multiplexed </span>
              <Plus className="check-icon" size={18} />
            </>
          ) : (
            <>
              <span>FASTQ Multiplexed </span>
              <Minus className="check-icon" size={18} />
            </>
          )
        }
        price={deliverData?.fastqDemultiplexed?.price}
      />

      {/* <SubmissionItem title="FASTQ Multiplexed" /> */}
      <SubmissionItem
        title={
          file ? (
            <>
              <span>Raw File </span> <Plus className="check-icon" size={18} />
            </>
          ) : (
            <>
              <span>Raw File </span> <Minus className="check-icon" size={18} />
            </>
          )
        }
        price={deliverData?.rawFiles?.price}
      />
      <div className="service-provider--total">Total: $ {deliverData.total}</div>
    </div>
  );
};

export default DataDeliverables;
