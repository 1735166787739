import React, { useContext } from 'react';

import './ServiceList.scss';
import ItemHeader from '../itemHeader/ItemHeader';
import ButtonWhite from '../../ui/button/buttonWhite/ButtonWhite';
import { RiTestTubeLine } from 'react-icons/ri';
import { Info } from 'lucide-react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import ButtonGradient from '../../ui/button/ButtonGradient';
import ordersContext from '../../context/orders/ordersContext';

const ServiceList = ({ item, ordersId }) => {
  const navigate = useNavigate();

  const { selectedOrderConfiguration, setSelectedOrderConfiguration } = useContext(ordersContext);

  const notShowRequired = !item.samplesRequired;
  const statusNotDone = item.samplesAmount === 0;
  const statusReady = item.samplesAmount > 0 || notShowRequired;

  const handleSubmitSample = () => {
    setSelectedOrderConfiguration(item.samplesFlow);

    navigate(`/submission/${item.id}/${ordersId}`);
  };

  return (
    <div className={`service ${statusReady ? 'border-blue' : statusNotDone ? 'border-red' : ''}`}>
      <ItemHeader
        //   isLoading={isLoading}
        //   setIsLoading={setIsLoading}
        props={item}
      />

      <div className="service__box">
        <div className="service__box-types">
          <>
            <div className="service__box-types item">
              {item.option.data.coverage && (
                <>
                  <div className="service__box-types item-title">Coverage</div>
                  <div className="service__box-types item-info">
                    {item.option.data.coverage}
                    {item.option.data.output}
                  </div>
                </>
              )}
              {item.option.data.amount && (
                <>
                  <div className="service__box-types item-title">Amount</div>
                  <div className="service__box-types item-info">{item.option.data.amount}</div>
                </>
              )}
              {item.option.data.readType && (
                <>
                  <div className="service__box-types item-title">Read Type</div>
                  <div className="service__box-types item-info">{item.option.data.readType}</div>
                </>
              )}
            </div>
            <div className="service__box-types item">
              {/* <div className="service__box-types item-title">Flow Cell</div>
              <div className="service__box-types item-info">S4</div> */}
            </div>
            <div className="service__box-types item">
              {item.option.data.reads && (
                <>
                  <div className="service__box-types item-title">Reads</div>
                  <div className="service__box-types item-info">{item.option.data.reads}</div>
                </>
              )}
            </div>
          </>
        </div>
        {!notShowRequired ? (
          <div className="service__providebtn">
            {statusNotDone && (
              <div className="service__providebtn-infoText">
                <Info color="#E83434" className="info-icon" />
                <p>No sample information provided</p>
              </div>
            )}
            {statusReady && (
              <div className="service__providebtn-infoReady">
                <IoMdCheckmarkCircleOutline className="check-icon" />
                <p> Sample information provided</p>
              </div>
            )}
            {statusNotDone && (
              <ButtonGradient
                text="PROVIDE SAMPLE INFO"
                icon={<RiTestTubeLine size={24} className="quote-icon" />}
                //   disabled={!token}
                handleClick={handleSubmitSample}
              />
            )}
            {statusReady && (
              <ButtonWhite
                text="Edit SAMPLE INFO"
                icon={<RiTestTubeLine size={24} className="quote-icon" />}
                //   disabled={!token}
                handleClick={handleSubmitSample}
              />
            )}
          </div>
        ) : (
          <div className="service__providebtn-NoSampleInfo">
            <p> Service has no Sample Submission </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceList;
