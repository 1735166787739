import React, { useContext, useState } from 'react';
import { ChevronDown, ChevronRight, Trash2 } from 'lucide-react';
import './TableFirstQa.scss';

import InputField from '../InputField/InputField';
import SampleInputSettings from './SampleInputSettings';
import submissionContext from '../../../../../../components/context/submissionContext/submissionContext';

const TableFirstQaStep = ({
  rows,
  setRows,
  handleAddRow,
  handleDeleteRow,
  handleSubmitFirstStep,

  handleChange,
}) => {
  const { inputValues } = useContext(submissionContext);
  const [visibleRows, setVisibleRows] = useState({});

  const toggleVisibleRow = index => {
    setVisibleRows({
      ...visibleRows,
      [index]: !visibleRows[index],
    });
  };
  const isRowCompleted = row => {
    return Object.values(row).every(val => {
      if (typeof val === 'string') {
        return val.trim() !== '';
      }

      return true;
    });
  };

  const generateUniqueKey = row => {
    return `${row.id}`;
  };

  return (
    <div>
      <div className="add-qatable">
        <table className="add-qatable__table">
          <tbody>
            <tr>
              <th className="add-qatable__th">#</th>
              <th className="add-qatable__th">
                <div className="add-qatable__header">
                  <div className="add-qatable__header-sampleName">Sample Name</div>
                  <div className="add-qatable__header-primerName">Tube Barcode</div>
                  <div className="add-qatable__header-molarity">Sample Type</div>
                  <div className="add-qatable__header-lib">Library Prep. Kit</div>
                  <div className="add-qatable__header-volUl">Volume (uL)</div>
                  <div className="add-qatable__header-volume">Concentration</div>
                  <div className="add-qatable__header-unit">Concentration Unit</div>
                  <div className="add-qatable__header-qunt">Quantitation Method</div>
                  <div className="add-qatable__header-libsize">Library Size(bp)</div>
                  <div className="add-qatable__header-method">Library QC Method</div>
                  <div className="add-qatable__header-buffer">Buffer</div>
                  <div className="add-qatable__header-organism">Organism</div>
                  <div className="add-qatable__header-pair">Paired-End or Single-End Barcode</div>
                  <div className="add-qatable__header-bar">Barcode Length(bp)</div>

                  <div className="add-qatable__header-pool">Samples Pooled</div>
                </div>
              </th>
            </tr>
            {rows.map((row, index) => {
              const uniqueKey = generateUniqueKey(row);

              return (
                <tr
                  key={uniqueKey}
                  // className={isRowCompleted(row) ? "row-complete" : ""}
                >
                  <td className="add-qatable__id">{index + 1}</td>
                  <td>
                    <div className="add-qatable__inputsInfo">
                      <div
                        className={isRowCompleted(row) ? 'row-complete' : 'add-qatable__inputsRow'}
                      >
                        <div onClick={() => toggleVisibleRow(index)} className="add-qatable__icon">
                          {visibleRows[index] ? (
                            <ChevronDown className="add-qatable__icon-arrow" />
                          ) : (
                            <ChevronRight className="add-qatable__icon-arrow" />
                          )}
                        </div>
                        <SampleInputSettings
                          row={row}
                          setRows={setRows}
                          index={index}
                          rows={rows}
                          isRowCompleted={isRowCompleted}
                        />

                        <Trash2
                          className="delete-icon"
                          onClick={() => handleDeleteRow(row.id)}
                          color="#002060"
                          width={18}
                        />
                      </div>
                      {!visibleRows[index] && (
                        <div className="firstStep__formSection">
                          {(rows[index]?.quantitationMethod === 'qPCR' ||
                            rows[index]?.quantitationMethod === 'Nanodrop' ||
                            rows[index]?.quantitationMethod === 'Qubit' ||
                            rows[index]?.quantitationMethod === 'Bioanalyzer' ||
                            rows[index]?.quantitationMethod === 'TapeStation' ||
                            rows[index]?.quantitationMethod === 'Fragment Analyzer') && (
                            <h3 className="firstStep__formSection-title">
                              Quantification Parameters
                            </h3>
                          )}
                          <form
                            className="firstStep__form"
                            onSubmit={e => handleSubmitFirstStep(e, index)}
                          >
                            {rows[index]?.quantitationMethod === 'qPCR' && (
                              <InputField
                                label="QPCR (nM)"
                                placeholder="Enter Library Size"
                                unit="bp"
                                required={true}
                                name="qprc"
                                value={inputValues[index]?.qprc}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}
                            {rows[index]?.quantitationMethod === 'Nanodrop' && (
                              <InputField
                                label="Nanodrop)"
                                placeholder="Enter Nanodrop"
                                unit="bp"
                                required={true}
                                name="nanodrop"
                                value={inputValues[index]?.nanodrop}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}
                            {rows[index]?.quantitationMethod === 'Qubit' && (
                              <InputField
                                label="Qubit (nguL)"
                                placeholder="Enter Library Size"
                                unit="nguL"
                                required={true}
                                name="qubit"
                                value={inputValues[index]?.qubit}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}

                            {rows[index]?.quantitationMethod === 'Bioanalyzer' && (
                              <InputField
                                label="Fluorescent Assay (Bioanalyzer)"
                                placeholder="Enter Library Size"
                                unit="nguL"
                                required={true}
                                name="fluorescentAssayBioAnalyser"
                                value={inputValues[index]?.fluorescentAssayBioAnalyser}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}
                            {rows[index]?.quantitationMethod === 'Bioanalyzer' && (
                              <InputField
                                label="Library Size (Bioanalyzer)"
                                placeholder="Enter Library Size"
                                unit="bp"
                                required={true}
                                name="librarySizeBioAnalyser"
                                value={inputValues[index]?.librarySizeBioAnalyser}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}

                            {rows[index]?.quantitationMethod === 'TapeStation' && (
                              <InputField
                                label="Fluorescent Assay (Tapestation)"
                                placeholder="Enter Library Size"
                                unit="nguL"
                                required={true}
                                name="fluorescentAssayTapeStation"
                                value={inputValues[index]?.fluorescentAssayTapeStation}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}

                            {rows[index]?.quantitationMethod === 'TapeStation' && (
                              <InputField
                                label="Library Size (Tapestation)"
                                placeholder="Enter Library Size"
                                unit="bp"
                                required={true}
                                name="librarySizeTapeStation"
                                value={inputValues[index]?.librarySizeTapeStation}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}

                            {rows[index]?.quantitationMethod === 'Fragment Analyzer' && (
                              <InputField
                                label="Library Size (Fragment Analyzer)"
                                placeholder="Enter Library Size"
                                unit="bp"
                                required={true}
                                name="librarySizeFragmentAnalyser"
                                value={inputValues[index]?.librarySizeFragmentAnalyser}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}

                            {rows[index]?.quantitationMethod === 'Fragment Analyzer' && (
                              <InputField
                                label="Fluorescent (Fragment Analyzer)"
                                placeholder="Enter Library Size"
                                unit="nguL"
                                required={true}
                                name="fluorescentAssayFragmentAnalyser"
                                value={inputValues[index]?.fluorescentAssayFragmentAnalyser}
                                handleChange={e => handleChange(e, index)}
                              />
                            )}
                          </form>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div onClick={handleAddRow} className="add-qatable__addRow">
        + SAMPLES
      </div>
    </div>
  );
};

export default TableFirstQaStep;
