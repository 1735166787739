import React from 'react';
import './UpdatedUserCont.scss';
import ButtonConfirm from '../ui/button/buttonConfirm/ButtonConfirm';
import ButtonGradient from '../ui/button/ButtonGradient';
import { Col, Row } from 'antd';

const RemoveAccount = () => {
  return (
    <div className="change-box">
      <h3 className="change-box__title">Delete My Account</h3>
      <div className="change-box__remove">
        <p>
          <b>You can delete your account.</b>
        </p>
        <p>All data associated with your account would be permanently lost.</p>
        <p>If you confirm this action, you will receive a confirmation message to your email.</p>
      </div>

      <Row justify="space-between">
        <Col span={9}>
          <ButtonConfirm text="CANCEL" />
        </Col>
        <Col span={13}>
          <ButtonGradient text="CONFIRM" />
        </Col>
      </Row>
    </div>
  );
};

export default RemoveAccount;
