import React from 'react';
import { Switch } from 'antd';

const TableRowDeliverables = ({
  include,
  setSelectedOption,
  selectedOption,
  handleSwitchChange,
  rowTitle,
}) => {
  return (
    <tr>
      <td>
        <div className="switcher">
          <div className="switcher__option">
            <div className={selectedOption ? 'no' : 'yes'} onClick={() => setSelectedOption(false)}>
              No
            </div>
            <Switch
              defaultChecked={selectedOption}
              onChange={handleSwitchChange}
              className="switcher__switch"
            />
            <div className={selectedOption ? 'yes' : 'no'} onClick={() => setSelectedOption(true)}>
              Yes
            </div>
          </div>
          <div className="stepTable__info">
            <h4>{rowTitle}</h4>
          </div>
        </div>
      </td>
      <td>
        <div>$ {include}</div>
      </td>
    </tr>
  );
};

export default TableRowDeliverables;
