import React from 'react';

const ShippingMethod = ({ shippingData }) => {
  let label = '';
  if (shippingData?.selectedMethod === 'labelShipping') {
    label = 'Shipping Label';
  } else if (shippingData?.selectedMethod === 'WalkUpOption') {
    label = 'Walk-up Option';
  } else if (shippingData?.selectedMethod === 'PickUpOption') {
    label = 'Pick-up Option';
  }

  return (
    <div className="service-provider">
      <div className="service-provider__label">{label}</div>
      <p className="service-provider__address">
        -
        <br />
        Attn: -
        <br />
        -<br />
        Phone: -
      </p>
    </div>
  );
};

export default ShippingMethod;
