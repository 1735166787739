import React, { useContext, useEffect, useState } from 'react';
import { Steps } from 'antd';
import './CheckOut.scss';
import FirstQaStep from './steps/FirstQaStep/FirstQaStep';
import SecondStep from './steps/SecondStep/SecondStep';
import ThirdStepPrimers from './steps/ThirdStep/ThirdStepPrimers';
import FinishStep from './steps/FinishStep/FinishStep';

import OrderSummary from './steps/OrderSummary/OrderSummary';
import ordersContext from '../../../components/context/orders/ordersContext';

const CheckOut = ({ handleCloseCheckout }) => {
  const [current, setCurrent] = useState(0);

  const [steps, setSteps] = useState([]);

  const { selectedOrderConfiguration, setSelectedOrderConfiguration } = useContext(ordersContext);

  const next = () => {
    setCurrent(prev => prev + 1);
  };

  const prev = () => {
    setCurrent(prev => prev - 1);
  };

  //Here we dynamicly check what steps should be shown based on backend data
  useEffect(() => {
    //Initially we have Order Summary
    const initialSteps = [
      {
        title: <h2 className="checkout__title">Order Summary</h2>,
        content: (
          <OrderSummary
            current={current}
            next={next}
            prev={prev}
            handleCloseCheckout={handleCloseCheckout}
            handleFirstStep={handleFirstStep}
            handleSecondStep={handleSecondStep}
            handleThirdStep={handleThirdStep}
            stepKey="order-summary"
          />
        ),
      },
    ];

    if (selectedOrderConfiguration.delivery) {
      initialSteps.unshift({
        title: <h2 className="checkout__title">Shipping Instructions</h2>,
        content: <FinishStep current={current} next={next} prev={prev} stepKey="delivery-option" />,
      });
    }

    if (selectedOrderConfiguration.primersAndInices) {
      initialSteps.unshift({
        title: <h2 className="checkout__title">Sequencing Primers and Indices</h2>,
        content: <ThirdStepPrimers current={current} next={next} prev={prev} stepKey="step-primers" />,
      });
    }

    if (selectedOrderConfiguration.dataDeliverables) {
      initialSteps.unshift({
        title: <h2 className="checkout__title">Data Deliverables</h2>,
        content: <SecondStep current={current} next={next} prev={prev} stepKey="data-deliverables" />,
      });
    }

    if (selectedOrderConfiguration.samplesDetails) {
      initialSteps.unshift({
        title: <h2 className="checkout__title">Sample Submission Details</h2>,
        content: <FirstQaStep current={current} next={next} prev={prev}  stepKey='sample-submission-details' />,
        
      });
    }

    setSteps(
      initialSteps.map((item, index) => ({
        ...item,
        key: index,
      }))
    );
  }, [selectedOrderConfiguration]);

  const handleFirstStep = () => {
    setCurrent(0);
  };

  const handleSecondStep = () => {
    setCurrent(1);
  };

  const handleThirdStep = () => {
    setCurrent(2);
  };

  const items = steps.map(item => ({
    key: item.key,
  }));

  return (
    <div className="checkout">
      <div className="checkout-container">
        {steps[current]?.title}

        <Steps current={current} items={items} responsive={false} progressDot={true} />
        <div 
        className='checkout__content'
        >{steps[current]?.content}</div>
      </div>
    </div>
  );
};

export default CheckOut;
