import React, { useContext, useEffect, useState } from 'react';
import { Pencil } from 'lucide-react';
import axios from 'axios';
import { BACKEND_URL } from '../../../services/serverData';
import UserCart from './userCart/UserCart';
import PersonalInfo from './content/personal/PersonalInfo';
import WorkInfo from './content/work/WorkInfo';

import RemoveProfile from './content/remove/RemoveProfile';
import { BiMinus, BiPlus } from 'react-icons/bi';
import ShippingUser from './content/shipping/ShippingUser';
import NotificationUser from './content/notification/NotificationUser';
import PaymentUser from './content/payment/PaymentUser';
import userAccountContext from '../../../components/context/userAccount/userAccountContext';
import ReactGA from "react-ga4"

const UserAcount = () => {
  ReactGA.send({
    hitType: "pageview",
    page: `/account`,
    title: "Account Page"
  })

  const { editedPersonalInfo } = useContext(userAccountContext);
  const [shippVisible, setShippVisible] = useState(true);
  const [userData, setUserData] = useState();
  const [updateUse, setUpdateUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payVisible, setPayVisible] = useState(true);

  const [editMode, setEditMode] = useState(false);
  const [editWorkMode, setEditWorkMode] = useState(false);
  const [editPayment, setEditPayment] = useState(false);
  const [editShipping, setEditShipping] = useState(false);

  const handleShippVisible = () => {
    setShippVisible(!shippVisible);
  };
  const handlePayVisible = () => {
    setPayVisible(!payVisible);
  };

  const handleEditPersonalInfo = () => {
    setEditMode(true);
  };

  const handleEditWorkInfo = () => {
    setEditWorkMode(true);
  };

  const handleEditPayment = () => {
    setEditPayment(true);
  };

  const handleEditShipping = () => {
    setEditShipping(true);
  };

  useEffect(() => {
    const token = localStorage.getItem('gravl_token');
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BACKEND_URL}/admin/account`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data.data;

        setUserData(data);
        if (updateUse) {
          localStorage.removeItem('user_name');
          localStorage.removeItem('last_name');
          localStorage.setItem('user_name', data.personalInformation.firstName);
          localStorage.setItem('last_name', data.personalInformation.lastName);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Error:', error);
      }
    };
    fetchData();
  }, [updateUse]);

  const handleSavePersonalInfo = async () => {
    try {
      // setLoading(true);
      const token = localStorage.getItem('gravl_token');
      const response = await axios.post(`${BACKEND_URL}/admin/account`, editedPersonalInfo, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response?.status === 200) {
        setUpdateUser(prevState => !prevState);
      }
      // setLoading(false);
    } catch (error) {
      // setLoading(false);
      console.log('Error:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditWorkMode(false);
    setEditPayment(false);
    setEditShipping(false);
  };
  const handleSubmitEdit = () => {
    handleSavePersonalInfo();
    setEditMode(false);
    setEditWorkMode(false);
    setEditPayment(false);
    setEditShipping(false);
  };

  return (
    <div className="user-cart">
      <h2>Account</h2>
      <UserCart
        title="Personal Information"
        editIcon={<Pencil className="pencil-icon" size={18} />}
        content={
          <PersonalInfo
            data={userData?.personalInformation}
            editMode={editMode}
            handleSaveClick={handleSubmitEdit}
            handleCancelEdit={handleCancelEdit}
            loading={loading}
          />
        }
        handleEditPersonalInfo={handleEditPersonalInfo}
      />
      <UserCart
        title="Work Information"
        editIcon={<Pencil className="pencil-icon" size={18} />}
        content={
          <WorkInfo
            data={userData?.workInformation}
            editWorkMode={editWorkMode}
            handleSaveClick={handleSubmitEdit}
            handleCancelEdit={handleCancelEdit}
            loading={loading}
          />
        }
        handleEditPersonalInfo={handleEditWorkInfo}
      />
      <UserCart
        title="Shipping Address"
        collapse={
          shippVisible ? (
            <BiMinus className="collapse-icon" size={18} color="#00B0F0" />
          ) : (
            <BiPlus className="collapse-icon" size={18} color="#00B0F0" />
          )
        }
        content={
          <ShippingUser
            shippVisible={shippVisible}
            data={userData?.shippingInformation}
            handleEditPersonalInfo={handleEditShipping}
            editShippingMode={editShipping}
            handleSaveClick={handleSubmitEdit}
            handleCancelEdit={handleCancelEdit}
          />
        }
        handleShippVisible={handleShippVisible}
      />
      <UserCart
        title="Payment Methodss"
        collapse={
          payVisible ? (
            <BiMinus className="collapse-icon" size={18} color="#00B0F0" />
          ) : (
            <BiPlus className="collapse-icon" size={18} color="#00B0F0" />
          )
        }
        content={
          <PaymentUser
            payVisible={payVisible}
            data={userData?.paymentInformation}
            handleEditPersonalInfo={handleEditPayment}
            editPayment={editPayment}
            handleSaveClick={handleSubmitEdit}
            handleCancelEdit={handleCancelEdit}
          />
        }
        handleShippVisible={handlePayVisible}
      />
      <UserCart title="Notification Settings" content={<NotificationUser />} />
      <UserCart title="Delete My Account" content={<RemoveProfile />} />
    </div>
  );
};

export default UserAcount;
