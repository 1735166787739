import React from 'react';
import SubmissionItem from './item/SubmissionItem';
import { formatPrice } from '../../../../../../components/utils/currencyUtils';

const PaymentMethod = ({ data }) => {
  const totalOrderPrice =
    (data?.order?.itemPrice || 0) +
    (data?.orderDeliverablesConfiguration?.total || 0);

  return (
    <div className="service-provider">
      <div className="service-provider__label">
        Item Subtotal:{' '}
        {formatPrice(data?.order?.itemPrice, data?.order?.currency)}
      </div>
      <SubmissionItem
        title="Total"
        text={`${formatPrice(totalOrderPrice, data?.order?.currency)}`}
      />
    </div>
  );
};

export default PaymentMethod;
