import React, { useContext } from 'react';
import submissionContext from '../../../../../../components/context/submissionContext/submissionContext';

const DnaInputSettings = ({ row, setDnaSample, index, dnaSample }) => {
  const { qaData } = useContext(submissionContext);
  const options = ['DNA', 'RNA'];
  const optionsKit = ['ng/ul', 'nM'];

  const validOptions = [];
  if (qaData?.quantitationParameters?.Nanodrop === true) {
    validOptions.push('Nanodrop');
  }
  if (qaData?.quantitationParameters?.Qubit === true) {
    validOptions.push('Qubit');
  }

  validOptions.push('Other');

  return (
    <>
      {/* <input
        id="librarySize"
        placeholder="Sample..."
        type="tel"
        value={row.sampleNumber}
        onChange={(e) => {
            const newValue = e.target.value;

// Remove any non-digit characters
const sanitizedValue = newValue.replace(/\D/g, "");
          const updatedRows = [...dnaSample];
          updatedRows[index].sampleNumber = sanitizedValue;
          setDnaSample(updatedRows);
        }}
      /> */}
      <input
        id="librarySize"
        placeholder="Sample..."
        type="text"
        value={row.sampleName}
        onChange={e => {
          const updatedRows = [...dnaSample];
          updatedRows[index].sampleName = e.target.value;
          setDnaSample(updatedRows);
        }}
      />
      <input
        id="librarySize"
        placeholder="Sample..."
        type="text"
        value={row.tubeBar}
        onChange={e => {
          const updatedRows = [...dnaSample];
          updatedRows[index].tubeBar = e.target.value;
          setDnaSample(updatedRows);
        }}
      />

      <select
        id="selectSize"
        value={row.sampleType}
        onChange={e => {
          const updatedRows = [...dnaSample];
          updatedRows[index].sampleType = e.target.value;
          setDnaSample(updatedRows);
        }}
      >
        <option value="" disabled>
          Select...
        </option>

        {options.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>

      <input
        id="librarySize"
        placeholder="Volume..."
        type="tel"
        value={row.volumeUl}
        onChange={e => {
          const newValue = e.target.value;

          // Remove any non-digit characters
          const sanitizedValue = newValue.replace(/\D/g, '');
          const updatedRows = [...dnaSample];
          updatedRows[index].volumeUl = sanitizedValue;
          setDnaSample(updatedRows);
        }}
      />
      <input
        id="librarySize"
        placeholder="Concentration..."
        type="tel"
        value={row.concentration}
        onChange={e => {
          const newValue = e.target.value;

          // Remove any non-digit characters
          const sanitizedValue = newValue.replace(/\D/g, '');
          const updatedRows = [...dnaSample];
          updatedRows[index].concentration = sanitizedValue;
          setDnaSample(updatedRows);
        }}
      />
      <select
        id="selectSize"
        value={row.concentrationUnit}
        onChange={e => {
          const updatedRows = [...dnaSample];
          updatedRows[index].concentrationUnit = e.target.value;
          setDnaSample(updatedRows);
        }}
      >
        <option value="" disabled>
          Select...
        </option>

        {optionsKit.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>
      <select
        id="selectSize"
        value={row.quantitationMethod}
        onChange={e => {
          const updatedRows = [...dnaSample];
          updatedRows[index].quantitationMethod = e.target.value;
          setDnaSample(updatedRows);
        }}
      >
        <option value="" disabled>
          Select...
        </option>

        {validOptions.map((option, optionIndex) => (
          <option key={optionIndex} value={option}>
            {option}
          </option>
        ))}
      </select>
      <input
        id="librarySize"
        placeholder="Ratio..."
        type="tel"
        value={row.ratio}
        onChange={e => {
          const newValue = e.target.value;

          // Remove any non-digit characters
          const sanitizedValue = newValue.replace(/\D/g, '');
          const updatedRows = [...dnaSample];
          updatedRows[index].ratio = sanitizedValue;
          setDnaSample(updatedRows);
        }}
      />

      <input
        id="librarySize"
        placeholder="Buffer..."
        type="text"
        value={row.buffer}
        onChange={e => {
          const updatedRows = [...dnaSample];
          updatedRows[index].buffer = e.target.value;
          setDnaSample(updatedRows);
        }}
      />
      <input
        id="librarySize"
        placeholder="Organism..."
        type="text"
        value={row.organism}
        onChange={e => {
          const updatedRows = [...dnaSample];
          updatedRows[index].organism = e.target.value;
          setDnaSample(updatedRows);
        }}
      />
    </>
  );
};

export default DnaInputSettings;
